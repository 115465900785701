"use client";
import React, { useCallback, useState } from "react";

import clsx from "clsx";

import { ImageLegacy } from "@/components/displays";
import { lpProducts } from "@/components/domains/lp/Ingredients/const";

import styles from "./Ingredients.module.scss";

export function Ingredients() {
  const [products, setProducts] = useState<Record<string, boolean>>(
    lpProducts.reduce((acc, product) => ({ ...acc, [product.id]: false }), {})
  );

  const toggleProduct = useCallback((id: string) => {
    setProducts((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  }, []);

  return (
    <div className={clsx("pd__top__xl", styles.wrapper)}>
      <p className={clsx("text__xxl", "text__bold", "text__center", "mg__bottom__m")}>成分表示</p>
      <div className={styles.productDetail}>
        {lpProducts.map((product) => (
          <dl key={product.id}>
            <dt onClick={() => toggleProduct(product.id)}>
              <ImageLegacy
                src={product.src}
                alt={product.alt}
                aspectRatio={product.aspectRatio}
                containerClassName={styles.image}
              />
            </dt>
            {products[product.id] && (
              <dd>
                <ImageLegacy
                  src={product.detailSrc}
                  alt={product.alt}
                  aspectRatio={product.detailAspectRatio}
                  containerClassName={styles.image}
                />
              </dd>
            )}
          </dl>
        ))}
      </div>
    </div>
  );
}
