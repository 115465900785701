import { COMMON_START_SET } from "./COMMON_START_SET";
import { INVITE_START_SET } from "./INVITE_START_SET";
import { YAKISOBA_START_SET } from "./YAKISOBA_START_SET";

export const LPStartSets = {
  INVITE_START_SET,
  COMMON_START_SET,
  YAKISOBA_START_SET,
} as const;

export type LPStartSetsVariant = keyof typeof LPStartSets;
