import clsx from "clsx";

import { Row, RowProps } from "@/components/containers";

import styles from "./FormChapterTitle.module.scss";

export interface FormChapterTitleProps extends RowProps {}
export function FormChapterTitle({ children, className, ...rest }: FormChapterTitleProps) {
  return (
    <Row className={clsx(styles.root, className)} {...rest}>
      <p className={clsx("text__l", "text__bold")}>{children}</p>
    </Row>
  );
}
