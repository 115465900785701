"use client";
import { z } from "zod";
import { create } from "zustand";
import { persist } from "zustand/middleware";

import { DiscountPlanName } from "@/generated/open-api/schemas";
import { enumToZodEnum } from "@/utils/zod";

export const FormSchema = z.object({
  discountPlanName: z.enum(enumToZodEnum(DiscountPlanName)).optional(),
});

export type FormSchema = z.infer<typeof FormSchema>;

interface FormState {
  values: Partial<FormSchema>;
  setValue: <K extends keyof FormSchema>(field: K, value: FormSchema[K]) => void;
  setValues: (values: Partial<FormSchema>) => void;
  reset: () => void;
}

const initialValues: Partial<FormSchema> = {
  discountPlanName: DiscountPlanName["3MONTH"],
};

export const useFormStore = create<FormState>()(
  persist(
    (set) => ({
      values: initialValues,
      setValue: (field, value) => set((state) => ({ values: { ...state.values, [field]: value } })),
      setValues: (newValues) => set((state) => ({ values: { ...state.values, ...newValues } })),
      reset: () => set({ values: initialValues }),
    }),
    {
      name: "basefood-form",
      partialize: (state) => ({ values: state.values }),
      onRehydrateStorage: () => (state) => {
        if (state) {
          const result = FormSchema.safeParse(state.values);
          if (!result.success) {
            state.values = initialValues;
          }
        }
      },
    }
  )
);
