"use client";

import { useCallback, useEffect, useMemo, useState } from "react";

import { usePathname, useRouter, useSearchParams } from "next/navigation";

import { FullScreenProgressBar, LoadingOverlay } from "@/components/displays";
import { ThreeMonthPlanSelector } from "@/components/domains/3month";
import { AmazonPayPanel } from "@/components/domains/amazon/AmazonPayPanel";
import { useAmazonPay } from "@/components/domains/amazon/useAmazonPay";
import { useAuth } from "@/components/domains/auth";
import { useFormStore } from "@/components/domains/checkout";
import { FOOTER_BUTTON_TARGET_ID } from "@/components/domains/lp/consts";
import { useLPStore } from "@/components/domains/lp/store";
import { htmlToast } from "@/components/feedbacks/htmlToast";
import { useGetCartValidDates } from "@/generated/open-api/cart/cart";
import { DiscountPlanName } from "@/generated/open-api/schemas";
import { convertProductToNuxtStoreProduct } from "@/models/product/converters";
import { type ProductModel } from "@/models/product/type";
import { useSaveCheckoutProgress } from "@/queries";
import { useClientCartForm } from "@/storage";
import { useClientCartOrder } from "@/storage/useClientCartOrder";
import { validateFormInputs, withCsr } from "@/utils";
import {
  fireAmazonPayAction,
  getFormDataForAmazonPay,
  setFormDataForAmazonPay,
  setupAmazonPay,
  getAmazonCheckoutUrl,
} from "@/utils/amazon";
import { getErrorMessages } from "@/utils/error";
import { useCount, useOnce } from "@/utils/hooks";
import { pathnameWithQueryParams } from "@/utils/urlUtils";

import { CheckoutConfirmation } from "./CheckoutConfirmation";
import { getUtmContent, PageFrom, useLPForm, useSyncCart } from "./helper";
import { SetSelector } from "./SetSelector";
import { SelectedProducts } from "./SetSelector/types";
import { FormChapterTitle } from "../../checkout";
import {
  CheckoutForm,
  CheckoutFormValues,
  convertFormValuesToStorage,
  useFormValues,
} from "../../checkout-form";
import { transformRecommendSetProductToSelectOption } from "../helpers";
import { LPStartSets, LPStartSetsVariant } from "../settings";

export interface OnePageCheckoutFormProps {
  products: ProductModel[];
  formType: string;
  startSetVariant: LPStartSetsVariant;
  isInvited?: boolean;
  pageFrom: PageFrom;
  forceHideFreeSelection?: boolean;
  showInviteCouponDiscount?: boolean;
  initialSelectedIndex?: number;
}

export const OnePageCheckoutForm = withCsr(function OnePageCheckoutForm({
  products,
  formType,
  startSetVariant,
  isInvited = false,
  pageFrom,
  forceHideFreeSelection,
  showInviteCouponDiscount,
  initialSelectedIndex = 1,
}: OnePageCheckoutFormProps): React.ReactNode {
  const searchParams = useSearchParams();
  const { setValue: setFormStore, values: formStoreValues } = useFormStore();
  const [couponCode, forceSubmit, hideFreeSelection, cancelled] = [
    searchParams.get("coupon") ?? searchParams.get("code") ?? undefined,
    searchParams.get("confirm") === "true",
    searchParams.get("hide_free_select") === "true" || forceHideFreeSelection,
    searchParams.get("cancel") === "true",
  ] as const;

  const useCoupon = !!couponCode;
  // inviteページの場合、couponパラメータがなくてもinviteCodeをcouponとして使用する
  // inviteページにcouponコードがない場合、INVALIDにしてvalidationエラーを出す
  const initialCoupon = isInvited ? (couponCode ?? "INVALID") : useCoupon ? couponCode : undefined;
  const { setForm } = useClientCartForm();
  useOnce(() => {
    // couponがある場合は、フォームにセットすることで
    // PlanDiscountの価格がクーポンが適用された状態になる
    setForm((form) => ({ ...form, coupon: initialCoupon }));
  });

  // 確認ページ切り替え用フラグ
  const [confirming, setConfirming] = useState(false);
  const router = useRouter();

  const { persistentForm, confirm, submit, persistFormValues } = useLPForm();

  const { setAccessToken } = useAuth();
  const { setOrder } = useClientCartOrder();

  const [loadingCount, startLoading, endLoading] = useCount();
  const isLoading = loadingCount > 0;

  const scrollToFormTop = useCallback(() => {
    const element = document.getElementById(FOOTER_BUTTON_TARGET_ID);
    if (!element) return;

    const rect = element.getBoundingClientRect();
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const offset = rect.top + scrollTop;

    window.scrollTo(0, offset);
  }, []);

  const amazonPayData = useAmazonPay();
  const { amazonPayCheckoutSessionId, amazonPayCheckoutError, amazonPayEnable } = amazonPayData;

  /**
   * localStorageのcart.formがcheckoutページにも使われるため、isLPで絞り込まないと、checkoutページで選択した商品が選択されてしまう
   *
   * また、LP同士の競合を避けるため、lpPathが一致する場合のみ、選択された商品を取得する
   */
  const isCurrentLPData = persistentForm?.isLP && persistentForm?.lp_path === location.pathname;

  // 注文商品
  const {
    // このページのデータじゃない場合は、選択された商品を初期化
    selectIndex = initialSelectedIndex,
    setSelectIndex,
    isSubmitting,
    submittingProgress,
    resetSubmittingStatus,
  } = useLPStore();

  // ユーザーが選択したセット商品の情報
  const [selectedProducts, setSelectedProducts] = useState<SelectedProducts>(
    transformRecommendSetProductToSelectOption(
      products,
      LPStartSets[startSetVariant][initialSelectedIndex].products
    )
  );

  // localStorageに保存されたデータを復活（理論上persistentFormが値があれば、1回のみ実行される）
  useEffect(() => {
    // 該当LPのデータの場合のみ、復活
    if (isCurrentLPData) {
      // localStorageからselectIndexを復活
      if (persistentForm?.selectIndex != null) {
        // selectIndexが変わったら、別のuseEffectでselectedProductsを更新するため、ここでは更新しない
        setSelectIndex(persistentForm.selectIndex);
      }

      // localStorageから選択されたカスタマイズ商品を復活
      if (
        persistentForm?.selectIndex === LPStartSets[startSetVariant].length &&
        persistentForm?.products &&
        persistentForm?.products.length > 0
      ) {
        const persistentSelectedProducts = persistentForm.products.reduce<SelectedProducts>(
          (acc, product) => {
            return {
              ...acc,
              [product.variant_id]: product.quantity,
            };
          },
          {}
        );

        setSelectedProducts(persistentSelectedProducts);
      }
    }
  }, [
    isCurrentLPData,
    persistentForm?.products,
    persistentForm?.selectIndex,
    setSelectIndex,
    startSetVariant,
  ]);

  // selectIndexが変更されたら、選択された商品を更新
  useEffect(() => {
    // 「自分で選択する」以外の場合、選択された商品を更新
    if (LPStartSets[startSetVariant][selectIndex]?.products) {
      setSelectedProducts(
        transformRecommendSetProductToSelectOption(
          products,
          LPStartSets[startSetVariant][selectIndex].products
        )
      );
    }
  }, [products, selectIndex, startSetVariant]);

  // selectedProductsが変更されたら、カートを更新
  const { cart, discountPlanOptions } = useSyncCart({
    selectedProducts,
    discountPlanName: formStoreValues.discountPlanName,
  });

  const handleSelectedProductsChange = useCallback(
    async (newSelectedProducts: SelectedProducts, newSetIndex: number) => {
      setSelectedProducts(newSelectedProducts);
      setSelectIndex(newSetIndex);
    },
    [setSelectIndex]
  );
  const hasNormalProducts = (cart?.normalProducts?.length ?? 0) > 0;
  const hasFreezedProducts = (cart?.freezeProducts?.length ?? 0) > 0;

  // フォーム(商品選択を除く)
  const [formValues, setFormValues] = useFormValues({
    localStorageForm: persistentForm,
    sessionStorageForm: getFormDataForAmazonPay("amazon_pay_input_data"),
    customer: null,
    amazonPayData,
    hasBothProducts: hasNormalProducts && hasFreezedProducts,
    initialCoupon,
  });

  const { mutateAsync: validateForm } = useSaveCheckoutProgress();
  const handleChangedEmail = useCallback(
    async (email: string) => {
      if (email && cart && cart.products.length > 0) {
        try {
          await validateForm({
            data: { email, products: cart.products.map(convertProductToNuxtStoreProduct) },
          });
        } catch (e) {
          htmlToast.error(getErrorMessages(e));
        }
      }
    },
    [cart, validateForm]
  );

  useOnce(() => {
    if (amazonPayEnable || cancelled) scrollToFormTop();
  });

  const pathname = usePathname();
  const [resultReturnPath, reviewReturnPath] = useMemo(() => {
    const coupon = formValues.coupon;
    let resultReturnPath = pathname;
    let reviewReturnPath = pathname;
    if (coupon) {
      reviewReturnPath = pathnameWithQueryParams(pathname, { coupon });
      resultReturnPath = pathnameWithQueryParams(pathname, { coupon });
    }
    return [resultReturnPath, reviewReturnPath];
  }, [formValues.coupon, pathname]);

  const handleConfirm = useCallback(
    async (formValues: CheckoutFormValues) => {
      startLoading();
      try {
        if (formValues.paymentData.paymentMethod === "amazon" && !amazonPayEnable) {
          // amazon_pay_input_dataから正しくデータを復活できないため、formValuesをlocalStorageに保存
          // Amazonから戻ってきてもデータが維持されるように、localStorageに保存
          persistFormValues(formValues, selectedProducts, products);
          // AmazonPayが指定されているが、AmazonPayが有効でない場合は、AmazonPayの設定を行う
          // reviewReturnPathにcoupon他のクエリパラメータが含まれている場合は、そのまま渡す
          await setupAmazonPay({
            reviewReturnPath,
            resultReturnPath: pathnameWithQueryParams(resultReturnPath, { confirm: true }),
            isSubscription: cart?.isSubscription ?? false,
          });
          return;
        }
        if (await confirm(formValues, selectedProducts, products, cart?.totalPrice)) {
          setConfirming(true);
        } else {
          scrollToFormTop();
        }
      } finally {
        endLoading();
      }
      scrollToFormTop();
    },
    [
      amazonPayEnable,
      cart?.isSubscription,
      cart?.totalPrice,
      confirm,
      endLoading,
      persistFormValues,
      products,
      resultReturnPath,
      reviewReturnPath,
      scrollToFormTop,
      selectedProducts,
      startLoading,
    ]
  );

  const handleBack = useCallback(() => {
    setConfirming(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!cart) return;
    startLoading();
    scrollToFormTop();
    try {
      if (formValues.paymentData?.paymentMethod === "amazon" && !forceSubmit) {
        // AmazonPayのチェックアウトURLを取得してforceSubmit=trueの状態で戻ってくる
        setFormDataForAmazonPay("amazon_pay_input_data", convertFormValuesToStorage(formValues));
        const { url } = await getAmazonCheckoutUrl({
          amazonCheckoutSessionId: formValues.paymentData.amazonCheckoutSessionId!,
          amount: String(cart.totalPrice),
          is_subscription: cart.isSubscription ?? false,
          review_return_path: reviewReturnPath,
          result_return_path: pathnameWithQueryParams(resultReturnPath, { confirm: true }),
        });
        if (url) {
          location.href = url;
        }
        return;
      }
      const selectedProducts = LPStartSets[startSetVariant][selectIndex]?.products;
      validateFormInputs(persistentForm ?? {});

      const result = await submit(
        {
          ...persistentForm,
          // selectedProductsがない場合、「自分で選択する」に該当し、indexではなく、"other-selected"を指定
          set_names: [`${formType}_${selectedProducts ? selectIndex : "other-selected"}`],
        },
        formStoreValues
      );
      if (!result) return;
      // 注文できたらログイン状態にしておく
      if (result.token) {
        setAccessToken(result.token);
      }

      if (!result.order) return;
      // 注文情報をローカルストレージに保存
      setOrder(result.order);

      // 完了ページへリダイレクト
      router.push(
        `/checkout/5?from=${pageFrom}&utm_content=${getUtmContent(pageFrom, selectedProducts)}`
      );
    } catch (e) {
      htmlToast.error(getErrorMessages(e));
    } finally {
      endLoading();
    }
  }, [
    cart,
    endLoading,
    forceSubmit,
    formStoreValues,
    formType,
    formValues,
    pageFrom,
    persistentForm,
    resultReturnPath,
    reviewReturnPath,
    router,
    scrollToFormTop,
    selectIndex,
    setAccessToken,
    setOrder,
    startLoading,
    startSetVariant,
    submit,
  ]);

  useOnce(() => {
    if (forceSubmit) {
      handleSubmit();
    }
  }, Boolean(cart));

  // AmazonPay
  // AmazonPayのチェックアウトデータの取得でエラーが発生した場合の処理
  useOnce(async () => {
    if (amazonPayCheckoutError) {
      alert(amazonPayCheckoutError);
      await fireAmazonPayAction(amazonPayCheckoutSessionId, "changeAddress");
      return;
    }
  });

  const handleClickAmazonPay = useCallback(() => {
    setFormDataForAmazonPay("amazon_pay_input_data", convertFormValuesToStorage(formValues));
    // amazon_pay_input_dataから正しくデータを復活できないため、formValuesをlocalStorageに保存
    persistFormValues(formValues as CheckoutFormValues, selectedProducts, products);
  }, [formValues, persistFormValues, products, selectedProducts]);

  const { data: datesOptionsRes } = useGetCartValidDates();
  const deliveryDateOptions = useMemo(() => {
    let options =
      datesOptionsRes?.valid_dates?.map((validDate) => {
        // valueにはyyyy/MM/ddの形式で値が入っている
        return {
          value: validDate.value!,
          label: validDate.label!,
        };
      }) ?? [];
    const includesOutlet = cart?.products.some((product) => product.isOutlet);
    if (includesOutlet) {
      // Outletが含まれる場合は、配達日の先頭の7日間のみ表示
      const limitDates = 7;
      options = options.slice(0, limitDates);
    }
    return options;
  }, [cart?.products, datesOptionsRes?.valid_dates]);

  const handleSetDiscountPlan = useCallback(
    (planName: DiscountPlanName) => setFormStore("discountPlanName", planName),
    [setFormStore]
  );

  return (
    <>
      <LoadingOverlay
        isLoading={isLoading}
        data-testid={
          confirming ? "LoadingOverlay-confirm-container" : "LoadingOverlay-input-container"
        }
      >
        <div className="text__center mg__bottom__l mg__top__l">
          <div className="text__xl text__bold">お申し込みフォーム</div>
        </div>
        {!confirming ? (
          <>
            <SetSelector
              products={products}
              initialSelectIndex={selectIndex}
              initialSetProducts={selectedProducts}
              isInvited={isInvited}
              coupon={formValues.coupon}
              onChange={handleSelectedProductsChange}
              hideFreeSelect={hideFreeSelection}
              startSet={LPStartSets[startSetVariant]}
            />
            <FormChapterTitle className="mg__top__l mg__bottom__s">
              継続割引プランの選択
            </FormChapterTitle>
            <ThreeMonthPlanSelector
              value={formStoreValues.discountPlanName}
              onSelect={handleSetDiscountPlan}
              threeMonthsDisabled={formValues.paymentData?.paymentMethod === "daibiki"}
              disabledReason="支払い方法が代金引換のため、3ヶ月割引プランを選択できません。"
              discountPlanOptions={discountPlanOptions}
              isFirstSubscriptionOrder
              displayPlanOptionWithPrice
              discountType={showInviteCouponDiscount ? "invite-coupon" : "normal"}
            />
            <FormChapterTitle className="mg__top__l mg__bottom__s">ご注文方法</FormChapterTitle>
            {!amazonPayEnable && (
              <AmazonPayPanel
                isSubscription
                showAmazonPayBanner
                onClickPayButton={handleClickAmazonPay}
                resultReturnPath={resultReturnPath}
                reviewReturnPath={reviewReturnPath}
              />
            )}
            <CheckoutForm
              cart={cart}
              defaultValues={formValues}
              onChange={setFormValues}
              showBackButton={false}
              // Email、Passwordを常に入力可にするため、ログイン状態を考慮しない
              isLoggedIn={false}
              showLogin={false}
              showCoupon={!isInvited}
              onChangedEmail={handleChangedEmail}
              hiddenForm={false}
              confirmButtonVariants="yellow"
              onConfirm={handleConfirm}
              deliveryDateOptions={deliveryDateOptions}
              hideFooterAnnotation
              hideBillingAddressSelect
              confirmButtonText="注文内容を確認する"
            />
          </>
        ) : (
          <CheckoutConfirmation cart={cart} onClickBack={handleBack} onClickSubmit={handleSubmit} />
        )}
      </LoadingOverlay>
      {isSubmitting && (
        <FullScreenProgressBar
          percentage={submittingProgress}
          completeCallback={resetSubmittingStatus}
        />
      )}
    </>
  );
});
