import { useCallback, useEffect, useMemo } from "react";

import { keepPreviousData } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { format, isValid } from "date-fns";
import { DeepPartial } from "react-hook-form";

import { FormSchema } from "@/components/domains/checkout";
import { SelectedProducts } from "@/components/domains/lp/OnePageCheckoutForm/SetSelector/types";
import { htmlToast } from "@/components/feedbacks";
import { validateCart } from "@/generated/open-api/cart/cart";
import { createOrderPost } from "@/generated/open-api/order/order";
import { Address, DiscountPlanName, ValidateCartBody } from "@/generated/open-api/schemas";
import { convertAddressToApiAddress } from "@/models/customer/converters";
// TODO: 型の類を別ファイルに切り出す
import { DeliveryReceiveOption, DeliveryTimeZone } from "@/models/delivery/consts";
import { convertProductToNuxtStoreProduct } from "@/models/product/converters";
import { ProductModel, ProductOptionModel } from "@/models/product/type";
import { usePostParsedCart } from "@/queries";
import { useClientCartCart, useClientCartForm } from "@/storage";
import { NuxtStoreCart } from "@/storage/types";
import { validateFormInputs } from "@/utils";
import { getErrorMessages } from "@/utils/error";
import { pushGtmEvent } from "@/utils/gtm";
import { objectEntries, objectValues } from "@/utils/object";

import { CheckoutFormValues } from "../../checkout-form";
import { useAppendCreditCardPaymentData } from "../../checkout-form/CheckoutForm/useAppendCreditCardPaymentData";
import { useLPStore } from "../store";

/**
 * フォームの状態管理と、サブミット処理を管理するカスタムフック
 * クライアントサイドでのみ利用可能
 * @returns
 */
export const useLPForm = () => {
  const { form: persistentForm, setForm: setPersistentForm } = useClientCartForm({
    isSubscription: true,
  });
  const form = useMemo(() => convertNuxtStoreFormToForm(persistentForm ?? {}), [persistentForm]);
  const { appendCreditCardPaymentData } = useAppendCreditCardPaymentData();

  const { selectIndex, setIsSubmitting, setSubmittingProgress, resetSubmittingStatus } =
    useLPStore();

  const persistFormValues = useCallback(
    (
      formValues: CheckoutFormValues,
      selectedProducts: SelectedProducts,
      products: ProductModel[]
    ) => {
      const newFormValues = convertFormValueToNuxtStoreForm(
        formValues,
        selectedProducts,
        products,
        selectIndex
      );

      setPersistentForm(newFormValues);
    },
    [selectIndex, setPersistentForm]
  );

  const confirm = useCallback(
    async (
      formValuesWithoutCreditCardData: DeepPartial<CheckoutFormValues>,
      selectedProducts: SelectedProducts,
      products: ProductModel[],
      totalPrice?: number
    ) => {
      let formValues = formValuesWithoutCreditCardData;
      if (formValues.paymentData?.paymentMethod === "credit") {
        try {
          formValues = await appendCreditCardPaymentData(formValues, {
            email: formValues.credentials?.email ?? "",
            amount: totalPrice ?? 0,
            is_subscription: true,
          });
          if (!formValues.paymentData?.last4 || !formValues.paymentData?.stripePaymentMethodId) {
            htmlToast.error("カード情報を入力してください");
            return false;
          }
        } catch (error) {
          if (error instanceof Error) {
            htmlToast.error(error.message);
          }

          return false;
        }
      }
      const newFormValues = convertFormValueToNuxtStoreForm(
        formValues,
        selectedProducts,
        products,
        selectIndex
      );

      try {
        // フロントエンド側でvalidateを行う
        // TODO: フォーム全体のバリデーションを行う
        validateFormInputs(newFormValues);
      } catch (e) {
        htmlToast.error(getErrorMessages(e));
        return false;
      }

      try {
        // TODO: checkoutページと共通化
        const body: ValidateCartBody = {
          accepts_marketing: newFormValues.accepts_marketing,
          create_account: newFormValues.create_account,
          delivery_date: newFormValues.delivery_date ?? "free",
          delivery_timezone: newFormValues.delivery_timezone ?? "free",
          different_billing_address: newFormValues.different_billing_address as Address | undefined,
          email: newFormValues.email ?? "",
          non_face_to_face_receipt:
            newFormValues.delivery_location_code === DeliveryReceiveOption.FaceToFace,
          payment_data: newFormValues.payment_data,
          payment_method: newFormValues.payment_method,
          products: newFormValues.products ?? [],
          set_names: [],
          shipping_address: newFormValues.shipping_address as Address,
          isInvite: newFormValues.isInvite ?? false,
          isLP: newFormValues.isLP ?? false,
          password: newFormValues.password,
          coupon: newFormValues.coupon,
        };
        const res = await validateCart(body);
        const cartErrors = objectValues(res.exception?.errors ?? {}).flatMap((errors) => errors);
        if (cartErrors.length) {
          htmlToast.error(cartErrors);
          return false;
        }
      } catch (e) {
        if (isAxiosError(e)) {
          // 例外の場合の型は微妙に200の場合と違う
          const cartErrors = e.response?.data.exception ?? [];
          if (cartErrors.length) {
            htmlToast.error(cartErrors);
            return false;
          }
        }
      }

      pushGtmEvent({
        event: "click_LP_confirm_button",
        type: "click_LP_confirm_button",
      });

      setPersistentForm(newFormValues);
      return true;
    },
    [setPersistentForm, selectIndex, appendCreditCardPaymentData]
  );

  const submit = useCallback(
    async (formValues: NuxtStoreCart["form"], formStoreValues: Partial<FormSchema>) => {
      setIsSubmitting(true);
      setSubmittingProgress(70);

      try {
        const res = await createOrderPost({
          ...formValues,
          set_names: JSON.stringify(formValues.set_names),
          accepts_marketing: !!formValues.accepts_marketing,
          create_account: !!formValues.create_account,
          delivery_date: formValues.delivery_date ?? "free",
          delivery_timezone: formValues.delivery_timezone ?? "free",
          email: formValues.email ?? "",
          payment_data: formValues.payment_data!,
          payment_method: formValues.payment_method ?? "",
          shipping_address: formValues.shipping_address as Address,
          products: formValues.products ?? [],
          different_billing_address: formValues.different_billing_address as Address | undefined,
          non_face_to_face_receipt:
            formValues.delivery_location_code === DeliveryReceiveOption.FaceToFace,
          discount_plan_name: formStoreValues.discountPlanName,
        });
        setSubmittingProgress(100);

        return res;
      } catch (e) {
        resetSubmittingStatus();
        if (isAxiosError(e)) {
          const errors = e.response?.data.exception ?? [];
          if (errors.length) {
            htmlToast.error(errors);
            return;
          }
        }
      }
    },
    [resetSubmittingStatus, setIsSubmitting, setSubmittingProgress]
  );

  return {
    form,
    persistentForm,
    confirm,
    submit,
    persistFormValues,
  };
};

export function convertFormValueToNuxtStoreForm(
  form: DeepPartial<CheckoutFormValues>,
  selectedProducts: SelectedProducts,
  products: ProductModel[],
  selectIndex?: number
): NuxtStoreCart["form"] {
  const deliveryOptions = form.deliveryOptions;
  const deliveryDate = deliveryOptions?.deliveryDate
    ? format(deliveryOptions?.deliveryDate, "yyyy/MM/dd")
    : undefined;
  const deliveryTimezone = deliveryDate ? deliveryOptions?.deliveryTimezone : undefined;

  return {
    coupon: form.coupon,
    email: form.credentials?.email ?? "",
    password: form.credentials?.password ?? "",
    accepts_marketing: form.credentials?.acceptsMarketing ?? false,
    products: products
      .filter((product) => selectedProducts[product.variantId] ?? 0 > 0)
      .map((product) => ({
        ...convertProductToNuxtStoreProduct(product),
        quantity: selectedProducts[product.variantId]!,
      })),
    delivery_date: deliveryDate ?? "free",
    delivery_timezone: deliveryTimezone ?? "free",
    delivery_location_code: deliveryOptions?.deliveryReceiveOption,
    payment_method: form.paymentData?.paymentMethod,
    payment_data: {
      amazon_checkout_session_id: form.paymentData?.amazonCheckoutSessionId,
      stripe_payment_method_id: form.paymentData?.stripePaymentMethodId,
      last4: form.paymentData?.last4,
    },
    shipping_address: convertAddressToApiAddress(form.shippingAddress),
    create_account: true,
    isLP: true,
    // TODO:isInvite: form.isInvite,
    isInvite: false,
    lp_path: location.pathname,
    selectIndex,
  };
}

export function convertNuxtStoreFormToForm(
  form: NuxtStoreCart["form"]
): DeepPartial<CheckoutFormValues> {
  const shippingAddress = form.shipping_address;

  // delivery_dateには、日付文字列または、"free"が入るためisValidで判別する
  let deliveryDate: Date | null = new Date(form.delivery_date ?? "");
  deliveryDate = isValid(deliveryDate) ? deliveryDate : null;

  return {
    credentials: {
      email: form.email,
      password: form.password,
      acceptsMarketing: form.accepts_marketing,
    },
    shippingAddress: {
      lastName: shippingAddress?.last_name,
      firstName: shippingAddress?.first_name ?? "",
      zip: shippingAddress?.zip,
      province: shippingAddress?.province,
      city: shippingAddress?.city,
      addressLine1: shippingAddress?.address_line1,
      addressLine2: shippingAddress?.address_line2,
      phone: shippingAddress?.phone,
    },
    deliveryOptions: {
      deliveryDate,
      deliveryTimezone: form.delivery_timezone as DeliveryTimeZone,
      deliveryReceiveOption: form.delivery_location_code as DeliveryReceiveOption,
    },
    paymentData: {
      paymentMethod: form.payment_method!,
    },
    coupon: form.coupon ?? "",
    // isInvite: !!form.isInvite,
  };
}

// バグかもしれませんが、「友だち紹介キャンペーン｜BASE FOOD」は invite.vue からそのまま
export type PageFrom =
  | "dietform"
  | "lpform"
  | "gameform"
  | "友だち紹介キャンペーン｜BASE FOOD"
  | "yakisobaform";

/**
 * TODO: この関数は、LP_DIET_01などの設定のquantityに依存し、ユーザーが選択した商品の数量によって変わる。
 * LP_DIET_01などの設定が変わったらバグるから、100003などのutm_contentの値をコントロールしやすくするように将来リファクタリングする
 */
export function getUtmContent(
  pageFrom: PageFrom,
  selectedProducts?: ProductOptionModel["products"]
) {
  if (pageFrom === "lpform" && selectedProducts) {
    if (selectedProducts[0].quantity === 2) {
      return 100003;
    } else {
      return 100004;
    }
  } else if (pageFrom === "dietform") {
    return 101002;
  } else if (pageFrom === "yakisobaform") {
    return 101002;
  }
  return 99999;
}

interface UseSyncCartParams {
  selectedProducts: SelectedProducts;
  discountPlanName?: DiscountPlanName;
}

/**
 * 商品選択が変更されたときにカート情報を同期するカスタムフック
 * APIからカート情報を取得し、ローカルストレージと同期する
 * @param selectedProducts
 * @returns
 */
export const useSyncCart = ({ selectedProducts, discountPlanName }: UseSyncCartParams) => {
  const { form } = useClientCartForm({ isSubscription: true });
  const { setClientCartCart } = useClientCartCart();
  const { data } = usePostParsedCart(
    {
      discountPlanName: discountPlanName === "3MONTH" ? "3MONTH" : undefined,
      lpPath: location.pathname,
      products: objectEntries(selectedProducts).map(([variantId, quantity]) => ({
        variantId,
        quantity: String(quantity ?? 0),
        subscription: true,
      })),
      // 配送料、支払額を計算するためには、フォームの情報が必要
      ...(form?.coupon && { coupon: form.coupon }),
      ...(form?.payment_method && { payment_method: form.payment_method }),
    },
    { placeholderData: keepPreviousData }
  );

  useEffect(() => {
    setClientCartCart(data?.cart);
  }, [data?.cart, setClientCartCart]);
  return { cart: data?.cart, discountPlanOptions: data?.discountPlanOptions };
};
