import { ComponentProps } from "react";

type CheckIconProps = Omit<ComponentProps<"svg">, "width" | "height"> & {
  width?: number;
  height?: number;
};

const originalWidth = 18;
const originalHeight = 14;
const aspectRatio = originalWidth / originalHeight;

export const CheckIcon = ({ color = "#78B428", width, height, ...props }: CheckIconProps) => {
  const finalWidth = width ? width : height ? Math.round(height * aspectRatio) : originalWidth;
  const finalHeight = height ? height : width ? Math.round(width / aspectRatio) : originalHeight;

  return (
    <svg
      width={finalWidth}
      height={finalHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.1136 13.2349L0.263593 7.35122C-0.0878643 6.99774 -0.0878643 6.42462 0.263593 6.0711L1.53636 4.79099C1.88781 4.43747 2.4577 4.43747 2.80915 4.79099L6.75 8.75445L15.1908 0.265109C15.5423 -0.0883695 16.1122 -0.0883695 16.4636 0.265109L17.7364 1.54522C18.0879 1.8987 18.0879 2.47183 17.7364 2.82534L7.3864 13.2349C7.0349 13.5884 6.46506 13.5884 6.1136 13.2349V13.2349Z"
        fill={color ?? "#78B428"}
      />
    </svg>
  );
};
