"use client";
import React, { useCallback, useState } from "react";

import { NextImageLP } from "@/components/displays";
import { lpProducts } from "@/components/domains/lp/ToggleSection/const";

import borderImage from "$/images/lp/yakisoba/border.jpg";

import styles from "./ToggleSection.module.scss";

export function ToggleSection() {
  const [products, setProducts] = useState<Record<string, boolean>>(
    lpProducts.reduce((acc, product) => ({ ...acc, [product.id]: false }), {})
  );

  const toggleProduct = useCallback((id: string) => {
    setProducts((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  }, []);

  return (
    <div className={styles.wrapper}>
      {lpProducts.map((product, index) => {
        const isOpen = products[product.id];
        return (
          <div key={product.id}>
            <NextImageLP src={product.titleSrc} alt={product.alt} />
            <dl>
              <dt onClick={() => toggleProduct(product.id)} className={styles.button}>
                <NextImageLP
                  src={isOpen ? product.opendSrc : product.closedSrc}
                  alt={product.alt}
                />
              </dt>
              {isOpen && (
                <dd>
                  <NextImageLP src={product.contentSrc} alt={product.alt} />
                </dd>
              )}
            </dl>
            {index !== lpProducts.length - 1 && <NextImageLP src={borderImage} alt={product.alt} />}
          </div>
        );
      })}
    </div>
  );
}
