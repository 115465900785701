import { productNameSkuMap } from "@/configs/skuDB";
import { ProductOptionModel } from "@/models/product/type";

import form14 from "$/images/lp/set14_form.png";
import form28 from "$/images/lp/set28_form.png";
import form46 from "$/images/lp/set46_form.png";

export const COMMON_START_SET: ProductOptionModel[] = [
  {
    name: "定番のパンをおためし 14袋セット",
    products: [
      { sku: productNameSkuMap.basebread_chocolate, quantity: 2 },
      { sku: productNameSkuMap.basebread_maple, quantity: 2 },
      { sku: productNameSkuMap.basebread_cinnamon, quantity: 2 },
      { sku: productNameSkuMap.basebread_koshian, quantity: 2 },
      { sku: productNameSkuMap.basebread_milk, quantity: 2 },
      { sku: productNameSkuMap.basebread_slice_raisin, quantity: 2 },
      { sku: productNameSkuMap.basebread_slice_orange, quantity: 2 },
    ],
    sub_title: "定番のパンをおためし",
    images: [
      {
        src: form14,
      },
    ],
  },
  {
    name: "からだと向き合いたいなら 28袋セット",
    products: [
      { sku: productNameSkuMap.basebread_chocolate, quantity: 6 },
      { sku: productNameSkuMap.basebread_maple, quantity: 4 },
      { sku: productNameSkuMap.basebread_koshian, quantity: 4 },
      { sku: productNameSkuMap.basebread_milk, quantity: 4 },
      { sku: productNameSkuMap.basebread_slice_raisin, quantity: 6 },
      { sku: productNameSkuMap.basebread_slice_orange, quantity: 4 },
    ],
    sub_title: "からだと向き合いたいなら",
    images: [
      {
        src: form28,
      },
    ],
  },
  {
    name: "家族の健康習慣のために 46袋セット",
    products: [
      { sku: productNameSkuMap.basebread_chocolate, quantity: 6 },
      { sku: productNameSkuMap.basebread_maple, quantity: 6 },
      { sku: productNameSkuMap.basebread_cinnamon, quantity: 6 },
      { sku: productNameSkuMap.basebread_koshian, quantity: 4 },
      { sku: productNameSkuMap.basebread_milk, quantity: 4 },
      { sku: productNameSkuMap.basebread_slice_raisin, quantity: 6 },
      { sku: productNameSkuMap.basebread_curry, quantity: 6 },
      { sku: productNameSkuMap.basebread_slice_plain, quantity: 4 },
      { sku: productNameSkuMap.basebread_slice_orange, quantity: 4 },
    ],
    sub_title: "家族の健康習慣のために",
    images: [
      {
        src: form46,
      },
    ],
  },
];
