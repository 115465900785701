import React from "react";

import clsx from "clsx";
import FadeLoader from "react-spinners/FadeLoader";

import { theme } from "@/utils/color";

import styles from "./LoadingOverlay.module.scss";

interface LoadingOverlayProps {
  isLoading: boolean;
  children: React.ReactNode;
  className?: string;
  "data-testid"?: string;
}

const testClassName = "bf-loading-overlay";

export function LoadingOverlay({
  isLoading,
  children,
  className,
  "data-testid": testId,
}: LoadingOverlayProps): React.ReactNode {
  return (
    <div className={clsx(styles.root, className)}>
      <div className={clsx(styles.overlay, !isLoading && styles.none, isLoading && testClassName)}>
        <FadeLoader color={theme.yellow} loading={true} />
      </div>
      <div className={clsx(isLoading && styles.filter)} data-testid={testId}>
        {children}
      </div>
    </div>
  );
}
