import clsx from "clsx";

import { ImageLegacy } from "@/components/displays";
import { ProductModel } from "@/models/product/type";
import { formatCurrency } from "@/utils/currency";

import styles from "./ProductListItem.module.scss";

interface ProductListItemProps {
  product: ProductModel;
  isFirstSubscription?: boolean;
}

export function ProductListItem({
  product,
  isFirstSubscription,
}: ProductListItemProps): React.ReactNode {
  const price = product.hasSubscription
    ? isFirstSubscription
      ? product.firstSubscriptionTotalPrice
      : product.afterSecondSubscriptionTotalPrice
    : product.normalTotalPrice;

  return (
    <div className={clsx("pd__bottom__m mg__top__m", styles.product)} key={product.variantId}>
      <dl className={clsx(styles.table)}>
        <dt className={clsx(styles.cell, styles.imageContainer)}>
          <ImageLegacy
            src={product.images[product.variantImageIndex]}
            alt={product.title}
            className={styles.image}
          />
        </dt>
        <dd className={styles.cell}>
          <p className="text__m">
            {product.title}
            <br />
            {product.variantTitle}
            <br />
            {product.hasSubscription && (
              <>
                <span className="tag yellow inline round mini">継続コース</span>
                <span className="wsnr">4週間ごと</span>
              </>
            )}
          </p>
        </dd>
      </dl>
      <dl className={clsx("", styles.table)}>
        <dt className={styles.cell}>
          <p className="text__m">数量： {product.quantity}</p>
        </dt>
        <dd className={clsx("text__right", styles.cell)}>
          <p className="text__l text__bold">
            {formatCurrency(price)}
            <sup className="sup">*</sup>
          </p>
        </dd>
      </dl>
    </div>
  );
}
