import { Column } from "@/components/containers";
import { Chip, MoneySpan } from "@/components/displays";

export interface DefaultPlanOptionDetailWithPriceProps {
  firstDiscountRate?: number;
  normalDiscountRate?: number;
  firstTotalPrice?: number;
  normalTotalPrice?: number;
}

export function DefaultPlanOptionDetailWithPrice({
  firstDiscountRate = 0,
  normalDiscountRate = 0,
  firstTotalPrice = 0,
  normalTotalPrice = 0,
}: DefaultPlanOptionDetailWithPriceProps): React.ReactNode {
  return (
    <Column>
      <div>
        <Chip color="gray">{`初回${firstDiscountRate}%OFF`}</Chip>
      </div>
      <MoneySpan className="text__bold text__xxl">{firstTotalPrice}</MoneySpan>
      <div className="text__s mg__top__s">
        {`2回目以降${normalDiscountRate}%OFF `}
        <MoneySpan className="text__bold text__black">{normalTotalPrice}</MoneySpan>
      </div>
    </Column>
  );
}
