"use client";

import clsx from "clsx";
import { FreeMode, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Image } from "@/components/displays";

import styles from "./YakisobaSlide.module.scss";

export function YakisobaSlide() {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      centeredSlides
      className={styles.slideWrap}
      pagination={{
        clickable: true,
        bulletClass: styles.bullet,
        bulletActiveClass: styles.bulletActive,
      }}
      modules={[FreeMode, Navigation, Pagination]}
      navigation={{
        enabled: true,
        prevEl: `.${styles.buttonPrev}`,
        nextEl: `.${styles.buttonNext}`,
      }}
    >
      <button className={clsx(styles.button, styles.buttonPrev)} aria-label="前へ">
        <svg viewBox="0 0 32 32">
          <path
            d="M18 11 L12 16 L18 21"
            stroke="#fff"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <button className={clsx(styles.button, styles.buttonNext)} aria-label="次へ">
        <svg viewBox="0 0 32 32">
          <path
            d="M13 11 L19 16 L13 21"
            stroke="#fff"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <SwiperSlide className={styles.slide}>
        <Image
          src="/assets/images/lp/yakisoba/slider_01.png"
          alt="在宅ワーカーの平日ランチに"
          width={630}
          height={880}
        />
      </SwiperSlide>
      <SwiperSlide className={styles.slide}>
        <Image
          src="/assets/images/lp/yakisoba/slider_02.png"
          alt="忙しい日のお昼休みに"
          width={630}
          height={880}
        />
      </SwiperSlide>
      <SwiperSlide className={styles.slide}>
        <Image
          src="/assets/images/lp/yakisoba/slider_03.png"
          alt="自炊をお休みたい日に"
          width={630}
          height={880}
        />
      </SwiperSlide>
    </Swiper>
  );
}
