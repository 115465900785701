"use client";
import { ChangeEvent, useCallback, useId, useState } from "react";

import clsx from "clsx";

import { Column, Row } from "@/components/containers";
import { Chip, SpotlightFrame } from "@/components/displays";
import { DiscountPlanName } from "@/generated/open-api/schemas";
import { DiscountPlanOptionModel } from "@/models/discountPlan/type";
import { useDiscountPlans } from "@/queries";

import styles from "./ThreeMonthPlanSelector.module.scss";
import { ThreeMonthDescriptionModal } from "../ThreeMonthDescriptionModal";
import { ThreeMonthPlanOptionDetail } from "./PlanOptionDetail";
import { DefaultPlanOptionDetail } from "./PlanOptionDetail/DefaultPlanOptionDetail";

export type ThreeMonthPlanSelectorValue = DiscountPlanName;

export interface ThreeMonthPlanSelectorProps {
  value?: ThreeMonthPlanSelectorValue;
  onSelect: (value: ThreeMonthPlanSelectorValue, e: ChangeEvent<HTMLInputElement>) => void;
  threeMonthsDisabled?: boolean;
  disabledReason?: string;
  // initialThreeMonthDiscountRateとinitialNormalDiscountRateがない場合 => 復帰者用プラン
  // initialThreeMonthDiscountRateとinitialNormalDiscountRateがある場合 => 初回購入者用プラン
  isFirstSubscriptionOrder?: boolean; // 初回購入かどうか
  displayPlanOptionWithPrice?: boolean; // 価格表示をするかどうか
  discountPlanOptions?: Record<DiscountPlanName, DiscountPlanOptionModel | undefined>;
  discountType?: "normal" | "invite-coupon";
}

export function ThreeMonthPlanSelector({
  value = "DEFAULT",
  onSelect,
  threeMonthsDisabled,
  disabledReason,
  isFirstSubscriptionOrder,
  displayPlanOptionWithPrice,
  discountPlanOptions,
  discountType = "normal",
}: ThreeMonthPlanSelectorProps): React.ReactNode {
  const idFor3Month = useId();
  const idForNormal = useId();
  const { data: discountPlans } = useDiscountPlans();
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value as ThreeMonthPlanSelectorValue;
      onSelect?.(newValue, e);
    },
    [onSelect]
  );

  const title = isFirstSubscriptionOrder ? `初回限定オプション` : `今だけ限定オプション`;

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Column gap={20}>
      <Column>
        <SpotlightFrame color="#78B428" gradient={40}>
          <p className={clsx("text__bold", styles.title)}>{title}</p>
        </SpotlightFrame>
        <label
          htmlFor={idFor3Month}
          className={clsx(
            styles.panel,
            value === "3MONTH" && styles.selected,
            threeMonthsDisabled && styles.disabled
          )}
        >
          <Row className={clsx(styles.optionHeader)}>
            <input
              id={idFor3Month}
              checked={value === "3MONTH"}
              type="radio"
              value={"3MONTH"}
              className={clsx(styles.radioInput)}
              onChange={handleChange}
              disabled={threeMonthsDisabled}
              data-testid={`ThreeMonthPlanSelector-apply-input`}
            />
            <Row justify="space-between" flexFull>
              <p className={clsx("text__m", "text__bold")}>
                3ヶ月割引プラン
                <sup className="sup">※1</sup>
              </p>
              <Chip>おすすめ</Chip>
            </Row>
          </Row>
          <Column className={clsx(styles.option)}>
            <ThreeMonthPlanOptionDetail
              discountPlanOption={discountPlanOptions?.["3MONTH"]}
              isFirstSubscriptionOrder={isFirstSubscriptionOrder}
              displayPlanOptionWithPrice={displayPlanOptionWithPrice}
              period={discountPlans?.["3MONTH"]?.period}
              discountType={discountType}
            />
          </Column>
        </label>
        <Column gap={10} className={styles.descriptionContainer}>
          {threeMonthsDisabled && <p className={clsx("text__red", "text__s")}>{disabledReason}</p>}
          <Row>
            <p
              onClick={() => setModalOpen(true)}
              className={clsx("text__black", styles.descriptionLink)}
            >
              プランの詳細を見る
            </p>
          </Row>
          <Row>
            <p className={clsx("text__gray__dark", "text__s", styles.caution)}>
              {`※1 初回購入日から90日間は、初回23%OFF、2回目以降13%OFFとなります。91日目以降は通常継続プランの割引率（10%OFF）が適用されます。\n`}
              {`※2 初回購入日から90日以内に3回以上のご購入が条件です。条件に満たなかった場合は本プランの適用対象外となり、通常継続プランとの差額を、期間終了日の翌日または解約時にご請求します。`}
            </p>
          </Row>
        </Column>
      </Column>
      <label
        htmlFor={idForNormal}
        className={clsx(styles.panel, value === "DEFAULT" && styles.selected)}
      >
        <Row className={clsx(styles.optionHeader)}>
          <input
            id={idForNormal}
            checked={value === "DEFAULT"}
            value={"DEFAULT"}
            type="radio"
            className={clsx(styles.radioInput)}
            onChange={handleChange}
            data-testid={`ThreeMonthPlanSelector-ignore-input`}
          />
          <p className={clsx("text__m", "text__bold")}>通常継続プラン</p>
        </Row>
        <Column className={clsx(styles.option)}>
          <DefaultPlanOptionDetail
            discountPlanOption={discountPlanOptions?.["DEFAULT"]}
            isFirstSubscriptionOrder={isFirstSubscriptionOrder}
            displayPlanOptionWithPrice={displayPlanOptionWithPrice}
          />
        </Column>
      </label>
      <ThreeMonthDescriptionModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </Column>
  );
}
