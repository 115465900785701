"use client";
import { useMemo } from "react";

import clsx from "clsx";
import { uniqBy } from "ramda";

import { ImageLegacy } from "@/components/displays/ImageLegacy";
import { Selector } from "@/components/inputs/Selector";
import { ProductModel } from "@/models/product/type";
import { formatCurrency } from "@/utils/currency";
import { usePreviousMemo } from "@/utils/hooks";

import styles from "./ProductListItem.module.scss";
import { UnitPrice } from "../UnitPrice";

export interface ProductListItemProps {
  product: ProductModel;
  quantity: number;
  isSubscription: boolean;
  isFirstSubscriptionOrder: boolean;
  onChange: (quantity: number) => void;
  onClickDeletion: (event: React.MouseEvent) => void;
}

export function ProductListItem({
  product,
  quantity,
  isSubscription,
  isFirstSubscriptionOrder,
  onChange,
  onClickDeletion,
}: ProductListItemProps): React.ReactNode {
  const subTotalPrice = useMemo(
    () =>
      (product.subscription
        ? isFirstSubscriptionOrder
          ? product.firstSubscriptionTotalPrice
          : product.afterSecondSubscriptionTotalPrice
        : product.normalTotalPrice) ?? 0,
    [product, isFirstSubscriptionOrder]
  );

  const quantityOptions = usePreviousMemo<{ value: number; label: string }[]>(
    (prev = []) => {
      const { quantity, selectOptions } = product;
      const options = selectOptions.map((option) => ({ value: option, label: `${option}` }));
      if (quantity && selectOptions.indexOf(quantity) === -1) {
        options.push({ value: quantity, label: `${quantity}` });
      }
      // 選択肢が消えないように、直前の選択肢と結合して重複を除去
      return uniqBy((option) => option.value, [...options, ...prev]).sort(
        (a, b) => a.value - b.value
      );
    },
    [product]
  );

  return (
    <div className={styles.cartItemRow}>
      <div className={styles.detailColumn}>
        <div className={styles.imageContainer}>
          {product.images && product.images.length > 0 && (
            <ImageLegacy
              src={product.images[product.variantImageIndex ?? 0] ?? ""}
              alt={product.title ?? ""}
              size={{ width: 80, height: 80 }}
              className={styles.image}
            />
          )}
        </div>

        <div className={styles.title}>
          <div className="text__m">
            {product.title}
            <br />
            {product.meal !== "組" && product.variantTitle}
          </div>
          <div className="text__left clear__pc">
            <UnitPrice
              product={product}
              isSubscription={isSubscription}
              isFirstSubscriptionOrder={isFirstSubscriptionOrder}
            />
          </div>
        </div>
      </div>

      <div className={styles.priceColumn}>
        <div className={clsx(styles.selection, "mg__top__s mg__bottom__s")}>
          <div className="clear__sp flex">
            <UnitPrice
              product={product}
              isSubscription={isSubscription}
              isFirstSubscriptionOrder={isFirstSubscriptionOrder}
            />
          </div>

          <Selector
            value={quantity}
            onChange={onChange}
            options={quantityOptions}
            className={styles.quantity}
          />

          <span className="text__m">{product.meal}</span>

          <span
            className="btn gray inline round text__black mini text__bold"
            onClick={onClickDeletion}
          >
            削除
          </span>
        </div>

        <div
          className={clsx(
            "text__right text__bold text__l",
            styles.totalPrice,
            isSubscription && "text__red"
          )}
        >
          {formatCurrency(subTotalPrice)}*
        </div>
      </div>
    </div>
  );
}
