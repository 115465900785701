interface GetDaibikiDisabledReasonParams {
  isOkihai: boolean;
  isPlanDiscounted: boolean;
}

export const getDaibikiDisabledReason = (params: GetDaibikiDisabledReasonParams) => {
  const { isOkihai, isPlanDiscounted } = params;
  const reasons: string[] = [];
  if (isPlanDiscounted) reasons.push("3ヶ月割引プラン");
  if (isOkihai) reasons.push("受取方法に置き配");
  if (reasons.length === 0) return;
  return `${reasons.join("、")}を選択しているため、代金引換をご利用いただけません。`;
};
