"use client";

import { useCallback, useEffect, useMemo, useState } from "react";

import clsx from "clsx";

import { Row } from "@/components/containers";
import { MoneySpan } from "@/components/displays";
import { useFormStore } from "@/components/domains/checkout";
import { groupProductsByTitle } from "@/components/domains/lp/helpers";
import { Modal, type ModalProps } from "@/components/feedbacks";
import { Button } from "@/components/inputs";
import { PRODUCT_SALE_PARAMETER } from "@/configs/system";
import { type ProductModel } from "@/models/product/type";
import { objectKeys } from "@/utils/object";

import { GroupedProduct } from "./GroupedProduct";
import { calcTotalPrice, useGetCartInfoOfCustomSet } from "./helpers";
import styles from "./ModalDialogCustomizeSet.module.scss";
import { PriceProgressBar } from "./PriceProgressBar";
import { SelectedProducts } from "../types";

export interface ModalDialogCustomizeSetProps extends Omit<ModalProps, "header" | "footer"> {
  products: ProductModel[];
  initialSelectedProducts?: SelectedProducts;
  isInvited?: boolean;
  onCancel?: () => void;
  onApply?: (selectedProducts: SelectedProducts) => void;
}

export function ModalDialogCustomizeSet({
  products,
  initialSelectedProducts,
  isInvited,
  open,
  closeModal,
  onCancel,
  onApply,
}: ModalDialogCustomizeSetProps): React.ReactNode {
  const [selectedProducts, setSelectedProducts] = useState(initialSelectedProducts || {});
  const {
    data: { cart: cartInfo },
    isLoading: isRefreshingCartInfo,
  } = useGetCartInfoOfCustomSet(selectedProducts, open);
  const { values: formStoreValues } = useFormStore();

  const totalPrice = useMemo(() => calcTotalPrice(cartInfo, isInvited), [cartInfo, isInvited]);
  const hasNormalProduct = Boolean(cartInfo?.normalProductTotalPrice);
  const hasFreezeProduct = Boolean(cartInfo?.freezeProductTotalPrice);

  const normalProductGroup = useMemo(
    () =>
      groupProductsByTitle(products.filter((product) => !product.isFreeze && !product.hiddenOnLp)),
    [products]
  );
  const frozenProductGroup = useMemo(
    () =>
      groupProductsByTitle(products.filter((product) => product.isFreeze && !product.hiddenOnLp)),
    [products]
  );

  useEffect(() => {
    // デフォルト値が変更された場合に反映する
    setSelectedProducts(initialSelectedProducts || {});
  }, [initialSelectedProducts]);

  const handleUpdateProduct = useCallback(
    (variantId: number) => async (value: number) => {
      setSelectedProducts((prev) => ({ ...prev, [variantId]: value }));
    },
    []
  );

  const handleDeleteProduct = useCallback(
    (variantId: number) => async () => {
      setSelectedProducts((prev) => ({ ...prev, [variantId]: 0 }));
    },
    []
  );

  const handleClearAll = useCallback(() => {
    setSelectedProducts({});
  }, []);

  const handleCancel = useCallback(() => {
    // 選択肢をリセットする
    setSelectedProducts(initialSelectedProducts || {});
    (onCancel ?? closeModal)();
  }, [closeModal, initialSelectedProducts, onCancel]);

  const handleApply = useCallback(() => {
    onApply?.(selectedProducts);
  }, [onApply, selectedProducts]);

  return (
    <Modal
      displayCloseButton={false}
      closeModal={closeModal}
      open={open}
      className={styles.modalContainer}
      footerClassName={styles.modalFooter}
      bodyClassName={styles.modalBody}
      header={
        <div className={styles.modalPrice}>
          <div className={styles.modalPriceWrap}>
            <p className="text__m text__bold text__flex__left">合計：</p>
            <MoneySpan className="text__xl text__red text__bold">{totalPrice}</MoneySpan>
          </div>

          {hasNormalProduct && <PriceProgressBar price={cartInfo?.normalProductTotalPrice} />}
          {hasFreezeProduct && (
            <PriceProgressBar price={cartInfo?.freezeProductTotalPrice} isFreeze />
          )}
        </div>
      }
      footer={
        <div className={styles.buttonWrapper}>
          <button
            type="button"
            className={clsx("btn btn__l inline round gray text__black", styles.buttonCancel)}
            onClick={handleCancel}
          >
            キャンセル
          </button>
          <button
            type="button"
            className={clsx(
              "btn btn__l inline round red angle__right text__black",
              styles.buttonSave
            )}
            disabled={isRefreshingCartInfo || !cartInfo?.validPurchase}
            onClick={handleApply}
          >
            変更を保存
          </button>
        </div>
      }
    >
      <Row align="center" justify="space-between">
        <p className="text__l text__bold">商品を選択する</p>
        <Button type="button" variants="gray" size="xs" rounded onClick={handleClearAll}>
          すべて削除
        </Button>
      </Row>
      {open && objectKeys(normalProductGroup).length > 0 && (
        <GroupedProduct
          cartInfo={cartInfo}
          groupedProducts={normalProductGroup}
          selectedProducts={selectedProducts}
          onChange={handleUpdateProduct}
          onClickDelete={handleDeleteProduct}
          discountPlanName={formStoreValues.discountPlanName}
        />
      )}
      {open && objectKeys(frozenProductGroup).length > 0 && (
        <GroupedProduct
          cartInfo={cartInfo}
          groupedProducts={frozenProductGroup}
          selectedProducts={selectedProducts}
          onChange={handleUpdateProduct}
          onClickDelete={handleDeleteProduct}
          discountPlanName={formStoreValues.discountPlanName}
          isFreeze
        />
      )}
      <div className={clsx("mg__top__m", styles.borderTop)}>
        <dl className="table__dl pd__bottom__s pd__top__m">
          <dt>
            <p className="text__m">小計</p>
          </dt>
          <dd className="text__right">
            <MoneySpan className="text__l text__bold">{cartInfo?.lineTotalPrice}</MoneySpan>
          </dd>
        </dl>
        <dl className="table__dl pd__bottom__s">
          <dt>
            <p className="text__m">配送料</p>
          </dt>
          <dd className="text__right">
            <MoneySpan
              className={clsx("text__l text__bold", !cartInfo?.normalShippingFee && "text__red")}
              suffix="**"
            >
              {cartInfo?.totalShippingFee}
            </MoneySpan>
          </dd>
        </dl>
        {isInvited && (
          <dl className="table__dl pd__bottom__s">
            <dt>
              <p className="text__m">値引き金額</p>
            </dt>
            <dd className="text__right">
              <MoneySpan className="text__l text__bold text__red">
                {PRODUCT_SALE_PARAMETER.inviteDiscount}
              </MoneySpan>
            </dd>
          </dl>
        )}
        <dl className="table__dl pd__top__m border__top">
          <dt>
            <p className="text__l text__bold wsnr pd__top__s">合計</p>
          </dt>
          <dd className="text__right">
            <p className="text__s text__red">継続コース初回価格</p>
            <MoneySpan className="text__xxl text__bold text__red">{totalPrice}</MoneySpan>
            <p className="text__s">
              2回目以降のご購入から
              <MoneySpan className="text__m text__bold">
                {cartInfo?.afterSecondTotalPrice}
              </MoneySpan>
            </p>
            <p className="text__s text__gray__dark mg__top__s">
              ※価格はすべて税込表示*税率8% **税率10%
            </p>
          </dd>
        </dl>
      </div>
    </Modal>
  );
}
