import { DiscountPlanName } from "@/generated/open-api/schemas";

interface DiscountPlanCheckoutGuide {
  summary: string;
  caution: string;
}

export const discountPlanCheckoutGuides: Record<
  DiscountPlanName,
  DiscountPlanCheckoutGuide | null
> = {
  "3MONTH": {
    summary: `初回購入日から90日間、通常継続プランの割引率に3%上乗せし、初回23%OFF、2回目以降13%OFFとなります。91日目以降は通常継続プランの割引率(10%)が適用されます。\n\n初回購入日から90日以内に3回以上の継続コースご購入が条件となります。`,
    caution: `期間途中の解約は可能ですが、条件未達成時は割引上乗せ分（3%）が期間終了日の翌日または解約時にかかります。`,
  },
  DEFAULT: null,
};

export const discountPlanPenaltyDescriptions: Record<DiscountPlanName, string | null> = {
  "3MONTH":
    "3ヶ月割引プランの条件（90日以内に3回以上のご購入）未達のため、通常継続コースとの差額を違約金としてご請求いたします。",
  DEFAULT: null,
};

export const discountPlanPenaltyStatusLabels: Record<string, string> = {
  NONE: "未払い",
  PAID: "支払い済み",
  REFUNDED: "返金済み",
};
