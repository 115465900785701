import clsx from "clsx";
import Image from "next/image";

import { Column, Row } from "@/components/containers";
import { Modal } from "@/components/feedbacks";

import threeMonthDiscountImage from "$/images/3month/3month-plan-discount.png";

import styles from "./ThreeMonthDescriptionModal.module.scss";

interface ThreeMonthDescriptionModalProps {
  open: boolean;
  onClose: () => void;
}

export function ThreeMonthDescriptionModal({
  open,
  onClose,
}: ThreeMonthDescriptionModalProps): React.ReactNode {
  return (
    <Modal
      open={open}
      closeModal={onClose}
      bodyClassName={styles.body}
      activeClassName={styles.modal}
      displayCloseButton={false}
      footer={
        <button type="button" className={clsx("btn", "inline", "gray", "square")} onClick={onClose}>
          OK
        </button>
      }
    >
      <Column gap={20}>
        <h3 className={clsx("text__l", "text__bold")}>3ヶ月割引プランについて</h3>
        <p>初回購入日より90日間、さらにお得にベースフードを続けられるプランです。</p>
        <Row flexFull justify="center">
          <Image
            src={threeMonthDiscountImage}
            alt="3ヶ月割引プラン"
            className={styles.threeMonthDiscountImage}
          />
        </Row>
        <p className={clsx("text__m", "text__bold")}>
          途中解約でも通常継続プランの割引率と同じで安心です。
          <sup className="sup">※2</sup>
        </p>
        <p className={clsx("text__gray__dark", "text__s", styles.caution)}>
          {`※1 初回購入日から90日間は、初回23%OFF、2回目以降13%OFFとなります。91日目以降は通常継続プランの割引率（10%OFF）が適用されます。\n`}
          {`※2 初回購入日から90日以内に3回以上のご購入が条件です。条件に満たなかった場合は本プランの適用対象外となり、通常継続プランとの差額を、期間終了日の翌日または解約時にご請求します。`}
        </p>
        <h4 className={clsx("text__l", "text__bold")}>プラン詳細</h4>
        <table className={styles.table}>
          <thead>
            <tr>
              <th></th>
              <th>通常継続プラン</th>
              <th>{`3ヶ月\n割引プラン`}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>割引率</th>
              <td>初回20%OFF、2回目以降10%OFF</td>
              <td>
                初回23%OFF、2回目以降13%OFF<span className="sup">※3</span>
              </td>
            </tr>
            <tr>
              <th>条件</th>
              <td>継続期間、注文回数の指定なし</td>
              <td>
                初回購入日から90日以内に3回以上の購入<span className="sup">※4</span>
              </td>
            </tr>
          </tbody>
        </table>
        <p className={clsx("text__gray__dark", "text__s", styles.caution)}>
          {`※3 初回購入日から90日間。91日目以降は通常継続プランの割引率（10%OFF）が適用となります。\n`}
          {`※4 条件に満たなかった場合は本プランの適用対象外となり、通常継続プランとの差額を、期間終了日の翌日または解約時にご請求します。`}
        </p>
      </Column>
    </Modal>
  );
}
