import clsx from "clsx";

import { Column } from "@/components/containers";
import { MoneySpan } from "@/components/displays";

import { PlanOptionDetailTitle } from "../PlanOptionDetailTitle";

export interface ThreeMonthPlanOptionDetailNormalProps {
  normalDiscountRate?: number;
  discountPrice?: number;
}

export function ThreeMonthPlanOptionDetailNormal({
  normalDiscountRate = 0,
  discountPrice = 0,
}: ThreeMonthPlanOptionDetailNormalProps): React.ReactNode {
  return (
    <Column>
      <PlanOptionDetailTitle className={clsx("text__bold", "text__l")}>
        {`${normalDiscountRate}%OFF`}
      </PlanOptionDetailTitle>
      <p className={clsx("text__bold", "text__s", "mg__top__s")}>
        通常価格より
        <MoneySpan className={clsx("text__bold", "text__m", "text__red")} suffix="お得">
          {discountPrice}
        </MoneySpan>
      </p>
    </Column>
  );
}
