import clsx from "clsx";

import { Column } from "@/components/containers";
import { Modal } from "@/components/feedbacks";
import { Button } from "@/components/inputs";

import styles from "./ThreeMonthSimpleModal.module.scss";

interface ThreeMonthSimpleModalProps {
  open: boolean;
  onClose: () => void;
}

export function ThreeMonthSimpleModal({
  open,
  onClose,
}: ThreeMonthSimpleModalProps): React.ReactNode {
  return (
    <Modal
      open={open}
      closeModal={onClose}
      bodyClassName={styles.body}
      displayCloseButton={false}
      footer={
        <Button variants="gray" onClick={onClose}>
          OK
        </Button>
      }
    >
      <Column gap={20}>
        <h3 className={clsx("text__l", "text__bold")}>3ヶ月割引プランについて</h3>
        <p>初回購入日より90日間、さらにお得にベースフードを続けられるプランです。</p>
        <h4 className={clsx("text__l", "text__bold")}>プラン詳細</h4>
        <table className={styles.table}>
          <tbody>
            <tr>
              <th>割引率</th>
              <td>{`初回23%OFF、\n2回目以降13%OFF ※1`}</td>
            </tr>
            <tr>
              <th>条件</th>
              <td>{`初回購入日から90日以内に3回以上の購入 ※2`}</td>
            </tr>
          </tbody>
        </table>
        <p className={clsx("text__gray__dark", "text__s", styles.caution)}>
          {`※1 初回購入日から90日間。91日目以降は通常継続プランの割引率（10%OFF）が適用となります。\n`}
          {`※2 条件に満たなかった場合は本プランの適用対象外となり、通常継続プランとの差額を、期間終了日の翌日または解約時にご請求します。`}
        </p>
      </Column>
    </Modal>
  );
}
