"use client";

import { useCallback, useEffect, useState } from "react";

import clsx from "clsx";
import { useSearchParams } from "next/navigation";

import { withCsr } from "@/utils";

import styles from "./ChatBotLoading.module.scss";

declare global {
  interface Window {
    btag: (event: string, kind: string) => void;
  }
}

const displayDelay = 1500;
const loadingTimeout = process.env.NEXT_PUBLIC_APP_ENV === "production" ? 5000 : 100; // ローカル環境では開発効率のため短く設定
const fadeOutTime = 3000;

// チャットボットが起動するまでのローディング表示。
// NuxtではefoKeyが設定される場合の処理も書かれていたが、現状GTMでキーとスクリプトを設定しているため、それらの処理は不要。
export const ChatBotLoading = withCsr(function ChatBotLoading(): React.ReactNode {
  const [isLoading, setIsLoading] = useState(true);
  const [isFadeOuting, setIsFadeOuting] = useState(false); // フェードアウトのアニメーション起動用クラス
  const params = useSearchParams();

  // フェードアウトの実行を開始する。アニメーション終了後にコンポーネント自体を消す
  const startFadeOut = useCallback(() => {
    setIsFadeOuting(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsFadeOuting(false);
    }, fadeOutTime);
  }, [setIsLoading]);

  useEffect(() => {
    // AmazonPayのSessionIDがある場合はAmazonPayの処理からの遷移なので、以降の処理を行わない
    if (params.get("amazonCheckoutSessionId") !== null) {
      startFadeOut();
      return;
    }

    // チャットボットがロードされたらローディング画像を非表示にする
    document.addEventListener("efo_loaded_ready", () => {
      // btag: チャットボット起動時にefoapp.jsで定義される関数
      window.btag("event", "open");
      setTimeout(() => startFadeOut(), displayDelay);
    });

    // 遅延後にローディング画像を非表示にする
    setTimeout(() => startFadeOut(), loadingTimeout);
  }, [params, startFadeOut]);

  return (
    <div>
      {isLoading && (
        <div className={clsx(styles.loading, isFadeOuting && styles.fadeOut)}>
          <div className={styles.image}>
            <div>
              <img src="https://asset.basefood.co.jp/lp/diet/img/chatbot_spinner.gif" alt="" />
            </div>
            <div className="wvLoadingCnt-text">ページにアクセス中です。</div>
          </div>
        </div>
      )}
    </div>
  );
});
