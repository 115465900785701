import clsx from "clsx";

import { PlanOptionDetailTitle } from "../PlanOptionDetailTitle";

export interface DefaultPlanOptionDetailFirstProps {
  firstDiscountRate?: number;
  normalDiscountRate?: number;
}

export function DefaultPlanOptionDetailFirst({
  firstDiscountRate = 0,
  normalDiscountRate = 0,
}: DefaultPlanOptionDetailFirstProps): React.ReactNode {
  return (
    <PlanOptionDetailTitle className={clsx("text__bold", "text__l")}>
      {`初回${firstDiscountRate}%OFF、\n2回目以降${normalDiscountRate}%OFF`}
    </PlanOptionDetailTitle>
  );
}
