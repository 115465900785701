"use client";

import clsx from "clsx";

import { Column } from "@/components/containers";
import { Hr } from "@/components/displays";
import { numberFormat } from "@/utils";

import styles from "./ThreeMonthRetentionCard.module.scss";

export interface ThreeMonthRetentionCardProps {
  restDays: number;
  restOrderCount: number;
  planDiscountedAmount: number;
}

export function ThreeMonthRetentionCard({
  restDays,
  restOrderCount,
  planDiscountedAmount,
}: ThreeMonthRetentionCardProps): React.ReactNode {
  return (
    <Column className={styles.root} align="center" justify="center" gap={20}>
      <Column>
        <p className={clsx("text__center", "text__l", styles.header)}>
          {`継続割引プランで\n割引された合計金額`}
        </p>
        <p className={clsx("text__center", "text__bold", "text__red", styles.price)}>
          {numberFormat(planDiscountedAmount)}
          <span className={clsx("text__xl")}>円</span>
        </p>
        <p className={clsx("text__gray__dark", "text__s", "text__center")}>
          ※通常継続プラン（初回20%OFF、２回目以降10%OFF）との差額
        </p>
      </Column>
      <Column className={styles.warning}>
        <p className={clsx("text__red", "text__bold", "text__m")}>
          今解約すると条件未達成のため、上記の違約金が自動請求されます。
        </p>
      </Column>
      <Hr noMargin dashed className={styles.hr} />
      <Column>
        <p className={clsx("text__m", "text__bold", "text__center", styles.footer)}>
          残り<span className={styles.focusText}>{restDays}</span>日以内に
          <span className={styles.focusText}>{restOrderCount}</span>回の注文で条件達成
        </p>
      </Column>
    </Column>
  );
}
