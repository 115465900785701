"use client";

import { useState } from "react";

import clsx from "clsx";

import { Faq, FaqProps } from "./Faq";
import styles from "./FaqSection.module.scss";

interface FaqSectionProps {
  faqSectionParams: FaqProps[];
}

const defaultDisplayQuestionsCount = 2;

export function FaqSection({ faqSectionParams }: FaqSectionProps): React.ReactNode {
  const [showSeeMoreQa, setShowSeeMoreQa] = useState(false);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-m-10 col-offset-m-1">
          <p className="text__bold text__xl text__center mg__bottom__l">よくある質問</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-m-10 col-offset-m-1">
          <div className={clsx("bg__white", styles.proteinReason)}>
            {faqSectionParams.slice(0, defaultDisplayQuestionsCount).map((faq, index) => (
              <div key={index}>
                <Faq {...faq} />
              </div>
            ))}

            {showSeeMoreQa &&
              faqSectionParams.slice(defaultDisplayQuestionsCount).map((faq, index) => (
                <div key={index}>
                  <Faq {...faq} />
                </div>
              ))}
            <div className="text__center mg__top__l">
              <button
                className={clsx(
                  "btn",
                  "btn__l",
                  "border__bk",
                  "inline",
                  "round",
                  "text__m",
                  "text__black",
                  styles.proteinQaBtn
                )}
                onClick={() => setShowSeeMoreQa(!showSeeMoreQa)}
              >
                {showSeeMoreQa ? "閉じる" : "もっとみる"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
