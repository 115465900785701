import clsx from "clsx";

import { PlanOptionDetailTitle } from "../PlanOptionDetailTitle";

export interface DefaultPlanOptionDetailNormalProps {
  normalDiscountRate?: number;
}

export function DefaultPlanOptionDetailNormal({
  normalDiscountRate = 0,
}: DefaultPlanOptionDetailNormalProps): React.ReactNode {
  return (
    <PlanOptionDetailTitle className={clsx("text__bold", "text__l")}>
      {`${normalDiscountRate}%OFF`}
    </PlanOptionDetailTitle>
  );
}
