import clsx from "clsx";

import { Column, Row } from "@/components/containers";

import { getDiscountMessage } from "./PlanDiscountStatusCard.helper";
import styles from "./PlanDiscountStatusCard.module.scss";

export interface PlanDiscountStatusCardProps {
  planName?: string;
  initialDiscountRate?: number | null;
  normalDiscountRate?: number | null;
  planPeriod?: number;
  className?: string;
  discountMessageColor?: "red" | "black";
}

export function PlanDiscountStatusCard({
  planName,
  initialDiscountRate,
  normalDiscountRate,
  planPeriod,
  className,
  discountMessageColor = "red",
}: PlanDiscountStatusCardProps): React.ReactNode {
  const hasPlanDiscount = !!normalDiscountRate && !!planName;
  const title = hasPlanDiscount ? "継続コース（4週間ごとにお届け）" : "1回のみお届け";
  const discountMessage = getDiscountMessage({
    planName,
    initialDiscountRate,
    normalDiscountRate,
    planPeriod,
  });

  return (
    <Column className={clsx(styles.root, className)} gap={10}>
      <Row>
        <p className={clsx("text__bold")}>{title}</p>
      </Row>
      {hasPlanDiscount && (
        <Row>
          <span className={clsx(styles.anchorMark)}>└ </span>
          <Column>
            <p className={clsx("text__bold")}>{planName}</p>
            <p
              className={clsx(
                "text__s",
                "text__bold",
                `text__${discountMessageColor}`,
                styles.display
              )}
            >
              {discountMessage}
            </p>
          </Column>
        </Row>
      )}
    </Column>
  );
}
