import { DiscountPlanOptionModel } from "@/models/discountPlan/type";

import { DefaultPlanOptionDetailFirst } from "./DefaultPlanOptionDetailFirst";
import { DefaultPlanOptionDetailNormal } from "./DefaultPlanOptionDetailNormal";
import { DefaultPlanOptionDetailWithPrice } from "./DefaultPlanOptionDetailWithPrice";

export interface DefaultPlanOptionDetailProps {
  discountPlanOption?: DiscountPlanOptionModel;
  isFirstSubscriptionOrder?: boolean;
  displayPlanOptionWithPrice?: boolean;
}

export function DefaultPlanOptionDetail({
  discountPlanOption,
  isFirstSubscriptionOrder,
  displayPlanOptionWithPrice,
}: DefaultPlanOptionDetailProps): React.ReactNode {
  return displayPlanOptionWithPrice ? (
    <DefaultPlanOptionDetailWithPrice
      firstDiscountRate={discountPlanOption?.first_discount_rate}
      normalDiscountRate={discountPlanOption?.normal_discount_rate}
      firstTotalPrice={discountPlanOption?.first_subscription_total_price}
      normalTotalPrice={discountPlanOption?.normal_subscription_total_price}
    />
  ) : isFirstSubscriptionOrder ? (
    <DefaultPlanOptionDetailFirst
      firstDiscountRate={discountPlanOption?.first_discount_rate}
      normalDiscountRate={discountPlanOption?.normal_discount_rate}
    />
  ) : (
    <DefaultPlanOptionDetailNormal normalDiscountRate={discountPlanOption?.normal_discount_rate} />
  );
}
