import { Image } from "@/components/displays";

import benefit1 from "$/images/lp/benefit/benefit_1.png";
import benefit2 from "$/images/lp/benefit/benefit_2.png";
import benefit3 from "$/images/lp/benefit/benefit_3.png";
import ImgBenefitTtl from "$/images/lp/benefit/benefit_ttl.png";

import styles from "./FirstSpecialBenefitsSection.module.scss";
export function FirstSpecialBenefitsSection(): React.ReactNode {
  return (
    <>
      <div className="row">
        <div className={"col-12 col-m-4 col-offset-m-4 mg__bottom__m mg__top__l text__center"}>
          <Image
            src={ImgBenefitTtl}
            alt="初めての方限定3つの初回特典"
            className={styles.meritTtl}
          />
        </div>
      </div>
      <div className="row">
        <div className={"col-12 col-m-4  col-offset-m-2 mg__bottom__m"}>
          <Image src={benefit1} alt="初めての方限定3つの初回特典" className={"mg__bottom__m"} />
          <Image src={benefit2} alt="初めての方限定3つの初回特典" />
        </div>
        <div className={"col-12 col-m-4 mg__bottom__m"}>
          <Image src={benefit3} alt="初めての方限定3つの初回特典" />
        </div>
      </div>
    </>
  );
}
