import { useMemo, useState } from "react";

import { format, isValid, parse } from "date-fns";
import { DeepPartial } from "react-hook-form";

import { useGetCartValidDatesSuspense } from "@/generated/open-api/cart/cart";
import { CustomerModel } from "@/models/customer/type";
import { DeliveryReceiveOption } from "@/models/delivery/consts";
import { PaymentMethod } from "@/models/payment/consts";
import { NuxtStoreCart, NuxtCommonAddress, NuxtCommonPaymentData } from "@/storage/types";
import { objectMerge } from "@/utils/object";

import {
  AddressValues,
  CheckoutFormValues,
  DeliveryOptionsValues,
  PaymentDataValues,
  UserCredentialsValues,
} from "./schema";
import { UseAmazonPayReturn } from "../../amazon";

export const convertAddressStorageToForm = (storageAddress: Partial<NuxtCommonAddress>) => {
  return {
    firstName: storageAddress.first_name ?? "",
    lastName: storageAddress.last_name ?? "",
    zip: storageAddress.zip ?? "",
    province: storageAddress.province ?? "",
    city: storageAddress.city ?? "",
    addressLine1: storageAddress.address_line1 ?? "",
    addressLine2: storageAddress.address_line2 ?? "",
    phone: storageAddress.phone ?? "",
  };
};

export const convertAddressFormToStorage = (address: Partial<AddressValues>) => {
  return {
    first_name: address.firstName ?? "",
    last_name: address.lastName ?? "",
    zip: address.zip ?? "",
    province: address.province ?? "",
    converted_province: address.province ?? "",
    city: address.city ?? "",
    address_line1: address.addressLine1 ?? "",
    address_line2: address.addressLine2 ?? "",
    phone: address.phone ?? "",
  };
};

export const convertPaymentDataStorageToForm = (storageForm: NuxtStoreCart["form"]) => {
  return {
    paymentMethod: storageForm?.payment_method ?? PaymentMethod.credit,
    creditCard: undefined,
  };
};

export const convertPaymentDataFormToStorage = (formPaymentData: Partial<PaymentDataValues>) => {
  return {
    payment_method: formPaymentData.paymentMethod ?? undefined,
    payment_data: {
      amazon_checkout_session_id: formPaymentData.amazonCheckoutSessionId,
    } as NuxtCommonPaymentData,
  };
};

export const convertDeliveryOptionsStorageToForm = (storageForm: NuxtStoreCart["form"]) => {
  const deliveryDate = parse(storageForm.delivery_date ?? "", "yyyy/MM/dd", new Date());
  const isValidDate = isValid(deliveryDate);

  return {
    deliveryDate: isValidDate ? deliveryDate : undefined,
    deliveryTimezone: storageForm?.delivery_timezone,
    deliveryReceiveOption: storageForm?.delivery_location_code ?? DeliveryReceiveOption.FaceToFace,
  } as DeliveryOptionsValues;
};

export const convertDeliveryOptionsFormToStorage = (
  formDeliveryOptions: Partial<DeliveryOptionsValues>
) => {
  return {
    delivery_date: formDeliveryOptions.deliveryDate
      ? format(formDeliveryOptions.deliveryDate, "yyyy/MM/dd")
      : "free",
    delivery_timezone: formDeliveryOptions.deliveryTimezone ?? "free",
    delivery_location_code:
      formDeliveryOptions.deliveryReceiveOption ?? DeliveryReceiveOption.FaceToFace,
  };
};

interface UseFormValuesProps {
  localStorageForm?: NuxtStoreCart["form"] | null;
  sessionStorageForm: NuxtStoreCart["form"] | null;
  customer: CustomerModel | null; // ログインしていない場合はnull
  amazonPayData: UseAmazonPayReturn;
  hasBothProducts: boolean;
  initialCoupon?: string;
}

/**
 * フォームの状態を管理するためのカスタムフック
 * 初期値をuseGetCartValidDatesSuspenseで取得するため、このhooksは呼び出し元をSuspenseでラップする必要があります。
 */
export const useFormValues = ({
  localStorageForm,
  sessionStorageForm,
  customer,
  amazonPayData,
  hasBothProducts,
  initialCoupon,
}: UseFormValuesProps) => {
  const { data: cartValidDatesData } = useGetCartValidDatesSuspense();

  const deliveryDateOptions = useMemo(
    () =>
      cartValidDatesData?.valid_dates?.map((validDate) => ({
        value: parse(validDate.value!, "yyyy/MM/dd", new Date()),
        label: validDate.label!,
      })) ?? [],
    [cartValidDatesData?.valid_dates]
  );
  return useState<DeepPartial<CheckoutFormValues>>(() =>
    objectMerge(
      getDefaultFormValues({
        localStorageForm,
        sessionStorageForm,
        customer,
        amazonPayData,
        deliveryDateOptions,
        hasBothProducts,
      }),
      { coupon: initialCoupon }
    )
  );
};

interface GetDefaultFormValuesProps {
  localStorageForm?: NuxtStoreCart["form"] | null;
  sessionStorageForm: NuxtStoreCart["form"] | null;
  customer: CustomerModel | null; // ログインしていない場合はnull
  amazonPayData: UseAmazonPayReturn;
  hasBothProducts: boolean;
  deliveryDateOptions: { value: Date; label: string }[];
}

export const getDefaultFormValues = ({
  localStorageForm,
  sessionStorageForm,
  customer, // ログインしていない場合はnull
  amazonPayData,
  hasBothProducts,
  deliveryDateOptions,
}: GetDefaultFormValuesProps): DeepPartial<CheckoutFormValues> => {
  const { amazonPayCheckoutData, amazonPayEnable, amazonPayCheckoutSessionId } = amazonPayData;
  let shippingAddress: DeepPartial<AddressValues> = {};
  if (amazonPayEnable) {
    shippingAddress = {
      ...amazonPayCheckoutData.shippingAddress,
      firstName: amazonPayCheckoutData.shippingAddress.firstName ?? "",
    };
  } else {
    const localStorageAddress = localStorageForm?.shipping_address;
    const formAddress = localStorageAddress ? convertAddressStorageToForm(localStorageAddress) : {};
    const customerAddress = { ...(customer?.address ?? {}) };
    shippingAddress = objectMerge(formAddress, customerAddress);
  }

  const credentials: DeepPartial<UserCredentialsValues> = {
    email: customer?.email ?? amazonPayCheckoutData?.email ?? localStorageForm?.email ?? "",
    password: localStorageForm?.password ?? "",
    acceptsMarketing: customer?.acceptsMarketing ?? true,
  };

  let billingAddress: DeepPartial<AddressValues> = {};

  if (amazonPayCheckoutData) {
    const sessionStorageBillingAddress = sessionStorageForm?.different_billing_address;
    if (sessionStorageBillingAddress) {
      billingAddress = convertAddressStorageToForm(sessionStorageBillingAddress);
    }
  } else if (localStorageForm?.different_billing_address) {
    const localStorageBillingAddress = localStorageForm?.different_billing_address;
    billingAddress = convertAddressStorageToForm(localStorageBillingAddress);
  }

  let billingAddressSelect = false;
  if (amazonPayCheckoutData) {
    billingAddressSelect = sessionStorageForm?.different_billing_address_select ?? false;
  } else {
    billingAddressSelect = localStorageForm?.different_billing_address_select ?? false;
  }

  let paymentData: DeepPartial<PaymentDataValues> = {};
  if (amazonPayEnable) {
    paymentData = {
      paymentMethod: PaymentMethod.amazon,
      amazonCheckoutSessionId: amazonPayCheckoutSessionId,
    };
  } else {
    if (localStorageForm) {
      paymentData = convertPaymentDataStorageToForm(localStorageForm);
    } else {
      // デフォルトクレジットカード払い
      paymentData = {
        paymentMethod: PaymentMethod.credit,
      };
    }
  }

  let deliveryOptions: DeepPartial<DeliveryOptionsValues> = {};

  if (amazonPayCheckoutData && sessionStorageForm) {
    // AmazonPayが有効な場合のみSessionStorageから取得
    deliveryOptions = convertDeliveryOptionsStorageToForm(sessionStorageForm);
  } else if (localStorageForm) {
    // ローカルストレージから取得
    deliveryOptions = convertDeliveryOptionsStorageToForm(localStorageForm);
  } else if (hasBothProducts) {
    // localStorage, sessionStorageにデータがなかったら、下記のルールが適用される
    /**
     * 常温・冷凍どちらもの商品が含まれている場合は、配送日指定の初期値を設定することで
     * 「日付を指定してお届け」が選択されている状態になります。
     */
    const firstOption = deliveryDateOptions[0];
    deliveryOptions = {
      deliveryDate: firstOption?.value ?? null,
    };
  }

  // 受取方法がundefinedの場合は対面受取に設定
  if (!deliveryOptions.deliveryReceiveOption) {
    deliveryOptions.deliveryReceiveOption = DeliveryReceiveOption.FaceToFace;
  }
  // 配送時間帯がundefinedの場合は指定ないに設定
  if (!deliveryOptions.deliveryTimezone) {
    deliveryOptions.deliveryTimezone = "free";
  }

  return {
    credentials,
    paymentData,
    shippingAddress,
    billingAddress,
    billingAddressSelect,
    deliveryOptions,
  };
};

export const convertFormValuesToStorage = (formValues: DeepPartial<CheckoutFormValues>) => {
  const {
    credentials,
    shippingAddress,
    billingAddress,
    billingAddressSelect,
    deliveryOptions,
    paymentData,
  } = formValues;

  const storageForm = {
    email: credentials?.email ?? "",
    password: credentials?.password ?? "",
    accepts_marketing: credentials?.acceptsMarketing ?? true,
    shipping_address: convertAddressFormToStorage(shippingAddress ?? {}),
    different_billing_address: billingAddress
      ? convertAddressFormToStorage(billingAddress)
      : undefined,
    different_billing_address_select: billingAddressSelect,
    ...convertDeliveryOptionsFormToStorage(deliveryOptions ?? {}),
    ...convertPaymentDataFormToStorage((paymentData ?? {}) as Partial<PaymentDataValues>),
  };
  return storageForm;
};
