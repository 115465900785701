import clsx from "clsx";

import styles from "./PlanOptionDetailTitle.module.scss";

export interface PlanOptionDetailTitleProps {
  className?: string;
  children: React.ReactNode;
}

export function PlanOptionDetailTitle({
  className,
  children,
}: PlanOptionDetailTitleProps): React.ReactNode {
  return <p className={clsx(className, styles.optionTitle)}>{children}</p>;
}
