interface GetDiscountMessageParams {
  planName?: string;
  initialDiscountRate?: number | null;
  normalDiscountRate?: number | null;
  planPeriod?: number;
}

export const getDiscountMessage = (params: GetDiscountMessageParams) => {
  const { planName, initialDiscountRate, normalDiscountRate, planPeriod } = params;
  const hasPlanDiscount = !!normalDiscountRate && !!planName;
  if (!hasPlanDiscount) return null;
  let message = "";
  if (initialDiscountRate) {
    message += `初回${initialDiscountRate}%OFF、2回目以降`;
  }
  if (planPeriod) {
    message += `（${planPeriod}日間）`;
  }
  message += `${normalDiscountRate}%OFF`;
  return message;
};
