export const egiftPointItems = [
  {
    id: 1,
    title: "住所を知らなくて\nも贈れる",
    icon: "/assets/images/egift/home.svg",
  },
  {
    id: 2,
    title: "サプライズで\n贈れる",
    icon: "/assets/images/egift/suprise.svg",
  },
  {
    id: 3,
    title: "LINE等で\n今すぐ贈れる",
    icon: "/assets/images/egift/message.svg",
  },
  {
    id: 4,
    title: "気持ちも一緒に\n伝えられる",
    icon: "/assets/images/egift/mail.svg",
  },
];
