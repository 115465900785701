import { keepPreviousData } from "@tanstack/react-query";

import { useFormStore } from "@/components/domains/checkout/store";
import { PRODUCT_SALE_PARAMETER } from "@/configs/system";
import { CartModel } from "@/models/cart/type";
import { useGetParsedCart } from "@/queries";

import { SelectedProducts } from "../types";

export function useGetCartInfoOfCustomSet(selectedProducts: SelectedProducts, open: boolean) {
  const { values } = useFormStore();
  return useGetParsedCart(
    {
      discount_plan_name: values.discountPlanName === "3MONTH" ? "3MONTH" : undefined,
      products: Object.entries(selectedProducts).map(([variantId, quantity]) => ({
        variant_id: variantId,
        quantity: (quantity ?? 0).toString(),
        subscription: true,
      })),
      isLP: true,
      lp_path: location.pathname,
      override_is_first_subscription_order: "true",
    },
    {
      query: {
        enabled: open,
        placeholderData: keepPreviousData,
      },
    }
  );
}

export function calcTotalPrice(cart?: CartModel, isInvited?: boolean) {
  if (cart?.totalPrice == null) {
    return 0;
  }

  // isInvitedの場合、inviteDiscountPriceを引く
  const inviteDiscount = isInvited ? PRODUCT_SALE_PARAMETER.inviteDiscount : 0;
  // 0円以下の場合は0円にする
  return Math.max(Number(cart.totalPrice) + inviteDiscount, 0);
}

export function calcProgressBarPercentage(cart?: CartModel) {
  if (cart?.normalProductTotalPrice == null) {
    return 0;
  }

  const borderPrice = cart.isFreeze
    ? PRODUCT_SALE_PARAMETER.minDeliveryAmountFrozen
    : PRODUCT_SALE_PARAMETER.minDeliveryAmountNormal;

  return Math.min((cart.normalProductTotalPrice / borderPrice) * 100, 100);
}
