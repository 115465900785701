"use client";

import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import clsx from "clsx";
import { Tooltip } from "react-tooltip";

import { nl2br } from "@/utils/jsx";
import { stripeElementIds, stripeElementStyles } from "@/utils/stripe";

import styles from "./CreditCardForm.module.scss";

export function CreditCardForm(): React.ReactNode {
  return (
    <div>
      <p className="text__s mg__bottom__s">カード番号</p>
      <CardNumberElement
        id={stripeElementIds.number}
        className="text__s"
        options={{ style: stripeElementStyles }}
      />
      <p className="text__s mg__bottom__s mg__top__m">有効期限</p>
      <CardExpiryElement
        id={stripeElementIds.expiry}
        className="text__s"
        options={{ style: stripeElementStyles }}
      />
      <div className="text__s mg__bottom__s mg__top__m tooltip__title">
        CVV/CVC
        <i
          id="credit-cvc-tooltip"
          className={clsx("fas fa-question-circle tooltip", styles.questionIcon)}
        />
        <Tooltip anchorSelect="#credit-cvc-tooltip">
          {nl2br(
            `カード裏面にある3桁のセキュリティコードです。\nAmerican Expressカードは前面にある4桁のコードです。`
          )}
        </Tooltip>
      </div>
      <CardCvcElement
        id={stripeElementIds.cvc}
        className="text__s"
        options={{ style: stripeElementStyles }}
      />
    </div>
  );
}
