"use client";
import { useMemo, useState } from "react";

import clsx from "clsx";
import { format, parse } from "date-fns";

import { Column, Row } from "@/components/containers";
import { Chip, Hr, MoneySpan } from "@/components/displays";
import { Button } from "@/components/inputs";
import { CustomerDiscountPlanModel } from "@/models/subscription/type";

import styles from "./ThreeMonthStatusAccordion.module.scss";
import { ThreeMonthSimpleModal } from "../ThreeMonthSimpleModal";

export interface ThreeMonthStatusAccordionProps {
  defaultOpen?: boolean;
  discountPlan: CustomerDiscountPlanModel;
}

export function ThreeMonthStatusAccordion({
  defaultOpen = false,
  discountPlan,
}: ThreeMonthStatusAccordionProps): React.ReactNode {
  const [open, setOpen] = useState(defaultOpen);
  const [modalOpen, setModalOpen] = useState(false);
  const endDate = useMemo(
    () => parse(discountPlan.endDateAsOrderArrivalDate, "yyyy/MM/dd", new Date()),
    [discountPlan.endDateAsOrderArrivalDate]
  );

  return (
    <Column className={styles.root} align="center" data-testid="ThreeMonthStatusAccordion">
      <Row align="center" justify="space-between" className={styles.title}>
        <div>
          <p className={clsx("text__white", "text__m", "text__bold")}>3ヶ月割引プラン</p>
        </div>
        <div>
          <Chip
            variant="outlined"
            color="light-green"
            data-testid="ThreeMonthStatusAccordion-discountPlan.rate.normal"
          >
            {format(endDate, "M月d日")}お届け予定分まで{discountPlan.discountPlan.rate.normal}
            %OFF
          </Chip>
        </div>
      </Row>
      {open && (
        <Column className={styles.body} align="center" justify="center" gap={20}>
          <Column align="center" justify="center">
            <p className={clsx("text__m", "text__bold")}>割引額の合計</p>
            <p className={clsx("text__xxl", "text__bold", styles.lightGreen)}>
              <MoneySpan
                data-testid="ThreeMonthStatusAccordion-discountPlan.totalDiscountedAmount"
                suffix=" 円"
                suffixClassName="text__l"
                noYenMark
              >
                {discountPlan.totalDiscountedAmount}
              </MoneySpan>
            </p>
            <p className={clsx("text__gray__dark", "text__m")}>※通常価格との比較</p>
          </Column>
          <Hr noMargin color="#c8c8c8" width="100%" />
          <Column align="start" className={styles.bodySection} gap={4}>
            <p className={clsx("text__bold", "text__m")}>これまでの購入回数</p>
            <p
              className={clsx("text__m")}
              data-testid="ThreeMonthStatusAccordion-current-order-count"
            >
              {discountPlan.currentOrderCount}回
            </p>
            {discountPlan.needToGoalCount > 0 ? (
              <p
                className={clsx("text__s", "text__gray__dark")}
                data-testid="ThreeMonthStatusAccordion-discountPlan.needToGoalCount"
              >
                条件達成まであと{discountPlan.needToGoalCount}回！（※割引プラン終了日まで）
              </p>
            ) : (
              <p className={clsx("text__s", "text__bold", styles.lightGreen)}>最低購入回数クリア</p>
            )}
          </Column>
          <Column align="start" className={styles.bodySection} gap={4}>
            <p className={clsx("text__bold", "text__m")}>割引プラン終了日</p>
            <p
              className={clsx("text__m")}
              data-testid="ThreeMonthStatusAccordion-discountPlan.endDateAsOrderArrivalDate"
            >
              {format(endDate, "yyyy年M月d日")}
              お届け予定分まで
            </p>
            <p
              className={clsx("text__s", "text__gray__dark")}
              data-testid="ThreeMonthStatusAccordion-discountPlan.startDate"
            >
              プラン開始日：
              {format(parse(discountPlan.startDate, "yyyy/MM/dd", new Date()), "yyyy年M月d日")}
            </p>
          </Column>
          <Row justify="left" className={styles.bodySection}>
            <Button variants="gray" size="sm" rounded onClick={() => setModalOpen(true)}>
              プランの詳細を見る
            </Button>
          </Row>
        </Column>
      )}
      <button className={styles.openButton} onClick={() => setOpen(!open)}>
        {!open && (
          <>
            詳細を表示
            <i className="fas fa-angle-down" />
          </>
        )}
        {open && (
          <>
            閉じる
            <i className="fas fa-angle-up" />
          </>
        )}
      </button>
      <ThreeMonthSimpleModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </Column>
  );
}
