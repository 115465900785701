import clsx from "clsx";

import { Column } from "@/components/containers";
import { DiscountPlanName } from "@/generated/open-api/schemas";

import styles from "./PlanCheckoutGuide.module.scss";
import { discountPlanCheckoutGuides } from "../consts";

export interface PlanCheckoutGuideProps {
  planName?: string;
  planId?: string;
}

export function PlanCheckoutGuide({ planName, planId }: PlanCheckoutGuideProps): React.ReactNode {
  if (!planName || !planId) {
    return null;
  }

  const discountPlanCheckoutGuide = discountPlanCheckoutGuides[planId as DiscountPlanName];
  if (!discountPlanCheckoutGuide) {
    return null;
  }

  return (
    <Column gap={10} className={styles.discountPlanCheckoutGuide}>
      <p className={clsx("text__m")}>{planName}</p>
      <Column>
        <p className={clsx("text__s")}>継続割引プラン概要:</p>
        <p className={clsx("text__s")}>{discountPlanCheckoutGuide.summary}</p>
      </Column>
      <Column>
        <p className={clsx("text__s")}>注意点:</p>
        <p className={clsx("text__s")}>{discountPlanCheckoutGuide.caution}</p>
      </Column>
    </Column>
  );
}
