import { productNameSkuMap } from "@/configs/skuDB";
import { ProductOptionModel } from "@/models/product/type";

export const YAKISOBA_START_SET: ProductOptionModel[] = [
  {
    name: "気軽に始める 8個セット",
    products: [
      { sku: productNameSkuMap.baseyakisoba_sauceyakisoba, quantity: 4 },
      { sku: productNameSkuMap.baseyakisoba_umakaramazesoba, quantity: 4 },
    ],
    sub_title: "気軽に始める 8個セット",
  },
];
