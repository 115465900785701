export const stripeElementStyles = {
  base: {
    color: "#32325D",
    fontWeight: 500,
    fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
    fontSize: "16px",
    fontSmoothing: "antialiased",
    "::placeholder": {
      color: "#CFD7DF",
    },
    ":-webkit-autofill": {
      color: "#e39f48",
    },
  },
};

export const stripeElementIds = {
  number: "card-number",
  expiry: "card-expiry",
  cvc: "card-cvc",
} as const;
