/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */
import { useMutation } from "@tanstack/react-query";
import type { MutationFunction, UseMutationOptions } from "@tanstack/react-query";
import type {
  CreateOrderPost400,
  CreateOrderPost500,
  CreateOrderPostBody,
  CreateOrderResponse,
} from ".././schemas";
import { customAxiosInstance } from "../../../configs/customAxiosInstance";
import type { ErrorType } from "../../../configs/customAxiosInstance";
import { customFormUrlEncodedFn } from "../../../configs/formUrlEncoded";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary create order
 */
export const createOrderPost = (
  createOrderPostBody: CreateOrderPostBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  const formUrlEncoded = customFormUrlEncodedFn(createOrderPostBody);
  return customAxiosInstance<CreateOrderResponse>(
    {
      url: `/order`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options
  );
};

export const getCreateOrderPostMutationOptions = <
  TError = ErrorType<CreateOrderPost400 | CreateOrderPost500>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrderPost>>,
    TError,
    { data: CreateOrderPostBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createOrderPost>>,
  TError,
  { data: CreateOrderPostBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createOrderPost>>,
    { data: CreateOrderPostBody }
  > = (props) => {
    const { data } = props ?? {};

    return createOrderPost(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateOrderPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createOrderPost>>
>;
export type CreateOrderPostMutationBody = CreateOrderPostBody;
export type CreateOrderPostMutationError = ErrorType<CreateOrderPost400 | CreateOrderPost500>;

/**
 * @summary create order
 */
export const useCreateOrderPost = <
  TError = ErrorType<CreateOrderPost400 | CreateOrderPost500>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrderPost>>,
    TError,
    { data: CreateOrderPostBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getCreateOrderPostMutationOptions(options);

  return useMutation(mutationOptions);
};
