import clsx from "clsx";

import { Column, Row } from "@/components/containers";
import { Hr } from "@/components/displays";
import { CheckIcon } from "@/components/icons";
import { DiscountPlanOptionModel } from "@/models/discountPlan/type";

import styles from "./ThreeMonthPlanOptionDetail.module.scss";
import { ThreeMonthPlanOptionDetailFirst } from "./ThreeMonthPlanOptionDetailFirst";
import { ThreeMonthPlanOptionDetailNormal } from "./ThreeMonthPlanOptionDetailNormal";
import { ThreeMonthPlanOptionDetailWithPrice } from "./ThreeMonthPlanOptionDetailWithPrice";

export interface ThreeMonthPlanOptionDetailProps {
  discountPlanOption?: DiscountPlanOptionModel;
  isFirstSubscriptionOrder?: boolean;
  displayPlanOptionWithPrice?: boolean;
  period?: number;
  discountType: "normal" | "invite-coupon";
}

export function ThreeMonthPlanOptionDetail({
  discountPlanOption,
  isFirstSubscriptionOrder,
  displayPlanOptionWithPrice,
  period = 90,
  discountType,
}: ThreeMonthPlanOptionDetailProps): React.ReactNode {
  const normalDiscountRate = discountPlanOption?.normal_discount_rate;
  const discountPrice =
    discountType === "normal"
      ? discountPlanOption?.discount_amount
      : discountPlanOption?.discount_amount_with_invite_coupon;
  const firstDiscountRate = discountPlanOption?.first_discount_rate;
  const firstTotalPrice = discountPlanOption?.first_subscription_total_price;
  const normalTotalPrice = discountPlanOption?.normal_subscription_total_price;

  return (
    <Column gap={20}>
      {displayPlanOptionWithPrice ? (
        <ThreeMonthPlanOptionDetailWithPrice
          discountPrice={discountPrice}
          firstDiscountRate={firstDiscountRate}
          normalDiscountRate={normalDiscountRate}
          period={period}
          firstTotalPrice={firstTotalPrice}
          normalTotalPrice={normalTotalPrice}
        />
      ) : isFirstSubscriptionOrder ? (
        <ThreeMonthPlanOptionDetailFirst
          discountPrice={discountPrice}
          firstDiscountRate={firstDiscountRate}
          normalDiscountRate={normalDiscountRate}
        />
      ) : (
        <ThreeMonthPlanOptionDetailNormal
          discountPrice={discountPrice}
          normalDiscountRate={normalDiscountRate}
        />
      )}
      <Hr noMargin dashed />
      <Column gap={8}>
        <Row align="center">
          <div className={styles.iconContainer}>
            <CheckIcon color="#fff" width={12} />
          </div>
          <p className={clsx("text__bold", "text__m")}>おトクな価格で始められる</p>
        </Row>
        <Row align="center">
          <div className={styles.iconContainer}>
            <CheckIcon color="#fff" width={12} />
          </div>
          <p className={clsx("text__bold", "text__m")}>
            途中解約でも通常継続プランの割引率と同じで安心※2
          </p>
        </Row>
      </Column>
    </Column>
  );
}
