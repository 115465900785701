"use client";

import { ImageLegacy, Scroll } from "@/components/displays";

import buttonImage from "$/images/lp/yakisoba/button.jpg";

import styles from "./AnchorButton.module.scss";

export function AnchorButton() {
  return (
    <div className={styles.wrapper}>
      <Scroll to="lp-form">
        <ImageLegacy
          src={buttonImage}
          alt="今すぐ試してみる"
          aspectRatio="25 / 4"
          className={styles.image}
        />
      </Scroll>
    </div>
  );
}
