import { Row, RowProps } from "@/components/containers";

import styles from "./SpotlightFrame.module.scss";

interface SpotlightFrameProps extends RowProps {
  children: React.ReactNode;
  color?: string;
  height?: number | string;
  gradient?: number;
}

export function SpotlightFrame({
  children,
  color = "white",
  height = 30,
  gradient = 20,
  style,
  ...rest
}: SpotlightFrameProps): React.ReactNode {
  const gradientStyle = {
    "--gradient-left": `-${gradient}deg`,
    "--gradient-right": `${gradient}deg`,
  };
  return (
    <Row align="center" style={{ ...style, ...gradientStyle }} {...rest}>
      <div className={styles.spotlightLeft} style={{ backgroundColor: color, height }} />
      {children}
      <div className={styles.spotlightRight} style={{ backgroundColor: color, height }} />
    </Row>
  );
}
