import { useCallback } from "react";

import { ValidationError } from "@/utils/error";
import { StripePaymentLogInfo, useStripePayment } from "@/utils/hooks";

// 追加する支払いデータの型
type WithPaymentData = {
  paymentData?: {
    paymentMethod?: string;
    stripePaymentMethodId?: string;
    last4?: string;
  };
};

/**
 * 3Dセキュア認証フローを経て、クレジットカード決済の情報を取得し、フォームデータに追加する
 */
export const useAppendCreditCardPaymentData = () => {
  const { getCreditCardPaymentInfo } = useStripePayment();

  const appendCreditCardPaymentData = useCallback(
    async <T extends WithPaymentData>(formValues: T, logInfo: StripePaymentLogInfo): Promise<T> => {
      if (formValues.paymentData?.paymentMethod !== "credit") {
        return formValues;
      }

      const { last4, stripePaymentMethodId } = await getCreditCardPaymentInfo(logInfo);
      if (!stripePaymentMethodId || !last4) {
        throw new ValidationError("カード情報の取得に失敗しました");
      }

      const newFormValues: T = {
        ...formValues,
        paymentData: {
          ...formValues.paymentData,
          stripePaymentMethodId,
          last4,
        },
      };

      return newFormValues;
    },
    [getCreditCardPaymentInfo]
  );

  return { appendCreditCardPaymentData };
};
