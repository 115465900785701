import { Column } from "@/components/containers";
import { Chip, MoneySpan } from "@/components/displays";

export interface ThreeMonthPlanOptionDetailWithPriceProps {
  firstDiscountRate?: number;
  normalDiscountRate?: number;
  discountPrice?: number;
  firstTotalPrice?: number;
  normalTotalPrice?: number;
  period: number;
}

export function ThreeMonthPlanOptionDetailWithPrice({
  firstDiscountRate = 0,
  normalDiscountRate = 0,
  discountPrice = 0,
  firstTotalPrice = 0,
  normalTotalPrice = 0,
  period,
}: ThreeMonthPlanOptionDetailWithPriceProps): React.ReactNode {
  return (
    <Column>
      <div>
        <Chip color="red">{`初回${firstDiscountRate}%OFF`}</Chip>
      </div>
      <MoneySpan className="text__bold text__xxl">{firstTotalPrice}</MoneySpan>
      <p className="text__bold text__s">
        通常価格より
        <MoneySpan className="text__bold text__m text__red" suffix="お得">
          {discountPrice}
        </MoneySpan>
      </p>
      <p className="text__s mg__top__s">
        {`2回目以降（${period}日間） ${normalDiscountRate}%OFF `}
        <MoneySpan className="text__bold text__black">{normalTotalPrice}</MoneySpan>
      </p>
    </Column>
  );
}
