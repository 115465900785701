import React from "react";

import clsx from "clsx";

import { Column, Row } from "@/components/containers";

import styles from "./FormSection.module.scss";

export interface FormSectionProps {
  title: string;
  subText?: string;
  action?: React.ReactNode;
  className?: string;
  titleClassName?: string;
  children: React.ReactNode;
}
export function FormSection({
  title,
  className,
  titleClassName,
  subText,
  action,
  children,
}: FormSectionProps): React.ReactNode {
  return (
    <section className={clsx(styles.section, className)}>
      <Column className={clsx(styles.titleContainer, "mg__bottom__m", "mg__top__l")}>
        <Row>
          <span className={clsx("text__m", "text__black", "text__bold", titleClassName)}>
            {title}
          </span>
          {action}
        </Row>
        {subText && (
          <p className={clsx("text__s", "text__normal", "text__black", "mg__top__s")}>{subText}</p>
        )}
      </Column>
      {children}
    </section>
  );
}
