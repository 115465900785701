import { PlanDiscountStatusCard } from "@/components/domains/3month";
import { CheckoutFormValues } from "@/components/domains/checkout-form";
import { SubmitSection } from "@/components/domains/checkout-form/SubmitSection";
import { useLPForm } from "@/components/domains/lp/OnePageCheckoutForm/helper";
import { CartModel } from "@/models/cart/type";

import { ContactSection } from "./ContactSection";
import { ContractSection } from "./ContractSection";
import { DeliverySection } from "./DeliverySection";
import { LocationSection } from "./LocationSection";
import { PaymentSection } from "./PaymentSection";
import { ProductSection } from "./ProductSection";

interface CheckoutConfirmationProps {
  cart?: CartModel;
  onClickBack: () => void;
  onClickSubmit: () => void;
}

export function CheckoutConfirmation({
  cart,
  onClickBack,
  onClickSubmit,
}: CheckoutConfirmationProps): React.ReactNode {
  const { form } = useLPForm();

  return (
    <>
      {cart?.discountPlan && (
        <PlanDiscountStatusCard
          discountMessageColor="black"
          initialDiscountRate={cart.discountPlan.rate.first}
          normalDiscountRate={cart.discountPlan.rate.normal}
          planName={cart.discountPlan.planName}
          planPeriod={cart.discountPlan.period}
        />
      )}
      {cart && <ProductSection cart={cart} />}
      {form.credentials?.email && <ContactSection email={form.credentials.email} />}
      <LocationSection shippingAddress={form.shippingAddress ?? {}} />
      {cart && <DeliverySection cart={cart} form={form as CheckoutFormValues} />}
      {form.paymentData?.paymentMethod && (
        <PaymentSection paymentMethod={form.paymentData.paymentMethod} />
      )}
      <ContractSection
        planName={cart?.discountPlan?.planName}
        planId={cart?.discountPlan?.discountPlanName}
      />
      <SubmitSection onClickBack={onClickBack} onClickSubmit={onClickSubmit} showReturnPolicy />
    </>
  );
}
